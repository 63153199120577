import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { toUpper } from 'lodash';
const url_tablas = "https://www.perfect-store.es/serverside/clientes.php";
class ListaClientes extends Component {

    constructor(props) {
        super(props);
        this.state = {
            clientList: []
        };
    }

    componentDidMount() {
        if (document.getElementById("titlePage")) {
            document.getElementById("titlePage").innerHTML = toUpper("Lista Clientes");
        }
        let http_request = new XMLHttpRequest();
        http_request.open("POST", url_tablas, true);
        let obj = {
            tipo_peticion: "",
            user: []
        }
        http_request.send(JSON.stringify(obj));
        http_request.onreadystatechange = () => {
            if (http_request.readyState === XMLHttpRequest.DONE && http_request.status === 200) {
                let data = JSON.parse(http_request.responseText);
                // console.log("DATA: ", data);
                this.setState({
                    clientList: data
                }, () => {
                    // console.log("state cambiado", this.state);
                    window.M.AutoInit();
                });
            }
        }

    }

    cargaNotificacion = (cliente) => {

        let html = []

        if (cliente.countFamilias !== 0) {
            if(cliente.kpiVal13 & cliente.kpiVal17 & cliente.kpiVal18 & cliente.kpiVal13){
                html.push(<span>{cliente.nombre} <i className="material-icons tooltipped left small yellow-text " data-position="right" data-tooltip="<a href='#' class='btn color-yellow'>LOS KPIS NO TIENEN NINGUNA FAMILIA ASIGNADA</a>" >error_outline</i></span>);
            }else{
                html.push(<span>{cliente.nombre} <i className="material-icons tooltipped left small green-text " data-position="right" data-tooltip="<a href='#'class='btn color-green'>Todo esta en orden</a>" >done</i></span>);
            }
        } else {
            html.push(<span>{cliente.nombre} <i className="material-icons tooltipped left small red-text " data-position="right" data-tooltip="<a href='#' class='btn color-red'>ESTE CLIENTE NO TIENE PRODUCTOS</a>" >error_outline</i></span>);
        }
        return html
    }


    chargeTableClients = () => {
        let html = []
        let list = this.state.clientList

        list.forEach((client) => {
            html.push(<tr key={client.id_cliente}>
                <td className="center padding5-5"> {client.id_cliente} </td>
            <td className="center padding5-5">{this.cargaNotificacion(client)}</td>
                <td className="row right margin0 padding5-5">
                    <div className="col s4"><Link className=" btn-floating tooltipped color-red " data-position="top" data-tooltip="<a href='#' class='btn black-tooltip'>Editar Cliente</a>" to={{ pathname: `/alta-cliente-edit/client-${client.id_cliente}`, state: { idCliente: client.id_cliente, nombreCliente: client.nombre } }}  ><i className="material-icons link-details size-1 white-text">border_color</i></Link></div>
                    <div className="col s4"><Link className=" btn-floating tooltipped color-purple " data-position="top" data-tooltip="<a href='#' class='btn black-tooltip'>Lista Kpi's</a>" to={{ pathname: `/lista-kpis/client-${client.id_cliente}`, state: { idCliente: client.id_cliente, nombreCliente: client.nombre } }}  ><i className="material-icons link-details size-1 white-text">format_list_numbered</i></Link></div>
                    <div className="col s4"><Link className=" btn-floating tooltipped " data-position="top" data-tooltip="<a href='#' class='btn black-tooltip'>Lista Objetivos</a>" to={{ pathname: `/lista-objetivos-ensenya/client-${client.id_cliente}`, state: { idCliente: client.id_cliente, nombreCliente: client.nombre } }}  ><i className="material-icons link-details size-1 white-text">center_focus_strong</i></Link></div>
                </td>
            </tr>);
        });

        return html;
    }

    render() {
        return (
            <div className="App">
                <div className="row">
                    <div className="container margin-top-2p ">

                        <Link to="/alta-cliente" ><div className="waves-effect waves-light btn right margin-bot3p"><i className="material-icons right">add</i>Nuevo Cliente</div></Link>
                        <table className="tabla-kpis">
                            <thead key="thead">
                                <tr>
                                    <th className="center "> Id </th>
                                    <th className="center "> Cliente </th>
                                    <th className="right  "> Accion </th>
                                </tr>
                            </thead>
                            <tbody key="tbody">
                                {this.chargeTableClients()}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }

}

export default ListaClientes