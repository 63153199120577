import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import ButtonBack from './Elementos/ButtonBack';
import _, { toUpper, isEmpty } from 'lodash';
const url_tablas = "https://www.perfect-store.es/serverside/lista_kpis.php";

const customModalFamilia = {

    content: {
        position: 'absolute',
        top: '5%',
        left: '5%',
        right: '5%',
        bottom: '5%',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        background: '#fff',
        overflow: 'none',
        WebkitOverflowScrolling: 'touch',
        borderRadius: '6px',
        outline: 'none',
        padding: '10px'
    }
};

class ListaKpis extends Component {

    constructor(props) {
        super(props);
        this.state = {
            idCliente: this.props.history.location.state.idCliente,
            nombreCliente: this.props.history.location.state.nombreCliente,
            kpis: [],
            datos: [],
            familias: [],
            modalFamilia: false,
            kpiSelectFamilia: '',
            datosKpis: [
                { num: 1, titulo: "Presencia Surtido", icon: "present_to_all", colorBack: "color-presencia-icon" },
                { num: 2, titulo: "Presencia Prod. Claves", icon: "present_to_all ", colorBack: "color-presencia-icon" },
                { num: 3, titulo: "Presencia Prod. Innovación", icon: "present_to_allt", colorBack: "color-presencia-icon" },
                { num: 4, titulo: "OOS Surtido", icon: "bug_report", colorBack: "color-presencia-icon" },
                { num: 5, titulo: "OOS Prod. Clave", icon: "bug_report", colorBack: "color-presencia-icon" },
                { num: 6, titulo: "OOS Prod. Innovación", icon: "bug_report", colorBack: "color-presencia-icon" },
                { num: 7, titulo: "P. Recomendado Surtido", icon: "euro_symbol", colorBack: "color-presencia-icon" },
                { num: 8, titulo: "P. Recomendado Clave", icon: "euro_symbol", colorBack: "color-presencia-icon" },
                { num: 9, titulo: "P. Recomendado Prod. Innovación", icon: "euro_symbol", colorBack: "color-presencia-icon" },
                { num: 10, titulo: "Altura Surtido", icon: "import_export", colorBack: "color-activacion-icon" },
                { num: 11, titulo: "Altura Prod. Clave", icon: "import_export", colorBack: "color-activacion-icon" },
                { num: 12, titulo: "Altura Prod. Innovación", icon: "import_export", colorBack: "color-activacion-icon" },
                { num: 13, titulo: "Bloque de Marca", icon: "view_comfy", colorBack: "color-activacion-icon" },
                { num: 14, titulo: "P. Señalizado Surtido", icon: "euro_symbol", colorBack: "color-activacion-icon" },
                { num: 15, titulo: "P. Señalizado Prod. Claves", icon: "euro_symbol", colorBack: "color-activacion-icon" },
                { num: 16, titulo: "P. Señalizado Prod. Innovación", icon: "euro_symbol", colorBack: "color-activacion-icon" },
                { num: 17, titulo: "POP Colocado en el Lineal", icon: "flag", colorBack: "color-activacion-icon" },
                { num: 18, titulo: "Cuota de Lineal", icon: "linear_scale", colorBack: "color-activacion-icon" },
                { num: 19, titulo: "Promociones en Lineal", icon: "new_releases", colorBack: "color-visibilidad-icon" },
                { num: 20, titulo: "Segunda Exposiciones", icon: "filter_2", colorBack: "color-visibilidad-icon" },
            ],
        };

    }
    componentDidMount() {
        if (document.getElementById("titlePage")) {
            document.getElementById("titlePage").innerHTML = toUpper("lista kpi " + this.state.nombreCliente);
        }
        let http_request = new XMLHttpRequest();
        http_request.open("POST", url_tablas, true);
        let obj = {
            tipo_peticion: "",
            user: {
                idCliente: parseInt(this.state.idCliente),
            }
        }
        http_request.send(JSON.stringify(obj));
        http_request.onreadystatechange = () => {
            if (http_request.readyState === XMLHttpRequest.DONE && http_request.status === 200) {
                let data = JSON.parse(http_request.responseText);
                // console.log("DATA: ", data);
                this.setState({
                    kpis: data[0],
                    datos: data['datosfamilias'],
                    familias: data['familias']
                }, () => {
                    window.M.AutoInit();
                    // console.log("state cambiado", this.state);
                });
            }
        }

    }
    cargarFamilias = (e) => {
        let kpi = e.currentTarget.getAttribute('kpi')
        // console.log("KPI SELE: ", kpi);
        this.openModalFilter(kpi);
    }
    afterOpenModal = () => {
        window.M.AutoInit();
    }
    openModalFilter = (num) => {
        this.setState({
            modalFamilia: true,
            kpiSelectFamilia: num
        });
    }
    closeModalFilter = () => {
        this.setState({
            modalFamilia: false
        });
    }
    updateFamilia = (e) => {
        // console.log("VALOR FILL: ", e.target.checked);
        // console.log("VALOR Familia: ", e.target.getAttribute("familia"));
        let kpiNum = this.state.kpiSelectFamilia;
        let familia = e.target.getAttribute("familia")
        if (e.target.checked) {
            let http_request = new XMLHttpRequest();
            http_request.open("POST", url_tablas, true);
            let objin = {
                tipo_peticion: "insert",
                user: {
                    kpi: kpiNum,
                    familia: familia,
                    idCliente: parseInt(this.state.idCliente)
                }
            }
            http_request.send(JSON.stringify(objin));
            http_request.onreadystatechange = () => {
                if (http_request.readyState === XMLHttpRequest.DONE && http_request.status === 200) {
                    // console.log("DATA: ", data);
                    let obj1 = {
                        kpi: kpiNum,
                        familia: familia
                    }
                    let datos = this.state.datos
                    datos.push(obj1);
                    this.setState({
                        datos: datos,
                    }, () => {
                        // console.log("state cambiado", this.state);
                    });
                }
            }
        } else {
            let http_request = new XMLHttpRequest();
            http_request.open("POST", url_tablas, true);
            let objdel = {
                tipo_peticion: "delete",
                user: {
                    kpi: kpiNum,
                    familia: familia,
                    idCliente: parseInt(this.state.idCliente)
                }
            }
            http_request.send(JSON.stringify(objdel));
            http_request.onreadystatechange = () => {
                if (http_request.readyState === XMLHttpRequest.DONE && http_request.status === 200) {
                    // console.log("DATA: ", data);
                    let datos = this.state.datos
                    _.remove(datos, { 'kpi': kpiNum, 'familia': familia })
                    this.setState({
                        datos: datos,
                    }, () => {
                        // console.log("state cambiado", this.state);
                    });
                }
            }
        }
    }
    isChecked = (familia) => {
        let familias = _.filter(this.state.datos, { 'kpi': this.state.kpiSelectFamilia, 'familia': familia });
        // console.log("VALOR FILL FAMILIA: ", familias);
        if (familias.length > 0) {
            return <input type="checkbox" className="filled-in" familia={familia} onChange={this.updateFamilia} defaultChecked />
        } else {
            return <input type="checkbox" className="filled-in" familia={familia} onChange={this.updateFamilia} />
        }
    }
    devolverTitulo = () => {
        let datos = this.state.datosKpis
        let kpi = this.state.kpiSelectFamilia
        let datoObj = [];
        _.each(datos, (dato) => {
            if (parseInt(dato.num) === parseInt(kpi)) {
                datoObj = dato
            }
        })
        // console.log("DATO TITULO: ", datoObj)
        return datoObj.num + ' | ' + datoObj.titulo
    }

    listaFamilias = () => {
        let html = [];
        // console.log("VALOR KPI: ", this.state.kpiSelectFamilia);
        // console.log("Familias: ", this.state.familias);
        if (this.state.familias.length === 0) {
            html.push(<li className="collection-item center-align"><h5>Este cliente no tiene Familias</h5></li>);
        } else {
            _.forEach(this.state.familias, (familia) => {
                // console.log("Familia: ", familia);
                html.push(
                    <li className="collection-item"> <label>
                        {this.isChecked(familia)}
                        <span>{familia}</span>
                    </label></li>
                )
            })
        }

        return html;
    }
    chargeTableKpis = () => {
        let html = []
        let list = this.state.kpis;
        let datos = this.state.datosKpis
        console.log(list)
        if (!isEmpty(list)) {
            _.each(datos, (dato) => {
                let valor = list["max_valor_kpi" + dato.num];
                if (parseInt(valor) !== 0) {
                    html.push(<tr key={dato.num} ><td className="left padding5-5"><i className={"material-icons icon-point " + dato.colorBack} >brightness_1</i><span className="icon-text"><b> Kpi {dato.num} || {dato.titulo}</b></span></td>
                        <td className="center padding5-5"> {list["max_valor_kpi" + dato.num]} </td>
                        <td className="center margin0 padding5-5">
                            <div className="col offset-s4 s2"> <Link className="tooltipped" data-position="left" data-tooltip="<a class='btn black-tooltip'>Percentiles</a>" to={{
                                pathname: `/detalles-kpi/kpi${dato.num}`,
                                state: {
                                    idCliente: this.state.idCliente,
                                    kpi: dato.num,
                                    titulo: dato.titulo
                                }
                            }
                            }><i className="material-icons btn-famili-per black-text">remove_red_eye</i></Link></div>
                            {dato.num === 13 || dato.num === 17 || dato.num === 18 || dato.num === 19 || dato.num === 20 ? <div className="col s2"><a className=" tooltipped " onClick={this.cargarFamilias} kpi={dato.num} data-position="right" data-tooltip="<span class='btn black-tooltip'>Asignar Familia</span>" ><i className="material-icons btn-famili-per black-text">style</i></a></div> : ''}
                        </td>
                    </tr>);
                }

            })
        }else{
            html.push(<tr key="9999" ><td colSpan="3" ><h5 align="center">Los Kpis no estan configurados</h5></td></tr>);
        }


        // for(let i = 1; i <= 20; i++){
        //     if(list['kpi'+i] === '1'){
        //         html.push(<tr key={i}><td className="center padding5-5"> Kpi {i} {datos} </td>
        //         <td className="center padding5-5"> {list["max_valor_kpi"+i]} </td>
        //         <td className="center padding5-5">
        //             <Link to={{ 
        //                     pathname: `/detalles-kpi/kpi${i}`,
        //                     state: { 
        //                         idCliente: this.state.idCliente,
        //                         kpi: i
        //                     }
        //                 }
        //             }><i className="material-icons link-details size-3 black-text">remove_red_eye</i></Link>
        //         </td>
        //     </tr>);
        //     }
        // }
        return html;
    }

    render() {
        return (
            <div className="App">
                <div className="row">
                    <div className="container margin-top-2p">
                        <ButtonBack text="Volver" event={this.props.history.goBack}></ButtonBack>
                        {/* <a className="btn-floating btn-large waves-effect waves-light teal right margin-bot3p" onClick={this.props.history.goBack}><i className="material-icons ">arrow_back</i></a> */}
                        <table className="tabla-kpis">
                            <thead key="thead">
                                <tr>
                                    <th className="center padding5-5"> Kpi </th>
                                    <th className="center padding5-5"> Valor </th>
                                    <th className="center padding5-5"> Accion </th>
                                </tr>
                            </thead>
                            <tbody key="tbody">
                                {this.chargeTableKpis()}
                            </tbody>
                        </table>
                    </div>
                </div>
                <Modal isOpen={this.state.modalFamilia}
                    onRequestClose={this.closeFamiliaModal}
                    onAfterOpen={this.afterOpenModal}
                    contentLabel="Filter Modal"
                    ariaHideApp={false}
                    style={customModalFamilia}>
                    <div className="row">
                        <div className=" col s12">
                            <h3>Familias KPI {this.devolverTitulo()}</h3>
                        </div>
                        <div className="input-field col s12 div-scroll-familia">
                            <ul className="collection">
                                {this.listaFamilias()}
                            </ul>
                        </div>
                        <button href="#!" className=" btn waves-effect margin-5-5 color-red right" onClick={this.closeModalFilter} >Cerrar</button>
                    </div>
                </Modal>
            </div>
        );
    }

}

export default ListaKpis