import React, { Component } from 'react';


class ButtonKPI extends Component {

    constructor(props) {
        super(props);
        // No llames this.setState() aquí!
        this.state = {
            id: props.num_kpi, 
            titulo: props.titulo,
            event: props.event,
            nameIcon: props.nameIcon,
            colorBack: props.colorBack
        };
    }

    render() {
        return (
            <div className="col s2 left box-kpi">
                <div className={"mask-kpi "+ this.state.colorBack} >
                    <div className="row">
                        <p className="col s2 offset-s2  "><i className="material-icons">{this.state.nameIcon}</i></p>
                        <p  className="tit-kpi col s6 "> KPI-{this.state.id}</p>
                    </div>

                    <p className="font-kpi" >{this.state.titulo}</p>
                    <div className="input-field ">
                            <input className="white-text validate center" titulo={this.state.titulo} num_kpi={this.state.id} id={"number_kpi_"+this.state.id} onBlur={this.state.event} type="number"/>
                            <label className="white-text" htmlFor={"number_kpi_"+this.state.id}></label>
                    </div>
                </div>
            </div>
        );
    }
}
export default ButtonKPI 