import React, { Component } from 'react';
import ButtonForm from './Elementos/ButtonForm';
import _, { toUpper } from 'lodash';
import ButtonBack from './Elementos/ButtonBack';
const url_tablas = "https://www.perfect-store.es/serverside/detalles_kpi.php";
class DetallesKpi extends Component {

    constructor(props) {
        super(props);
        // console.log(this.props.history.location.state);
        this.state = {
            idCliente: this.props.history.location.state.idCliente,
            kpi: this.props.history.location.state.kpi,
            titulo: this.props.history.location.state.titulo,
            data: [],
            update: [],
        };
    }

    componentDidMount() {
        if (document.getElementById("titlePage")) {
            document.getElementById("titlePage").innerHTML = toUpper( this.state.titulo + " || Detalles KPI  " + this.state.kpi );
        }
        let http_request = new XMLHttpRequest();
        http_request.open("POST", url_tablas, true);
        let obj = {
            tipo_peticion: "",
            user: {
                idCliente: parseInt(this.state.idCliente),
                kpi: parseInt(this.state.kpi)
            }
        }
        http_request.send(JSON.stringify(obj));
        http_request.onreadystatechange = () => {
            if (http_request.readyState === XMLHttpRequest.DONE && http_request.status === 200) {
                let data = JSON.parse(http_request.responseText);
                // console.log("DATA: ", data);
                this.setState({
                    data: data
                }, () => {
                    // console.log("state cambiado", this.state);
                });
            }
        }
    }

    cambioValor = (e) => {
        let porcentaje = e.target.getAttribute('percent');
        let valor = e.target.value;
        let datalist = this.state.update
        if (datalist) {
            datalist = _.remove(datalist, function (dato) {
                return dato.porcentaje !== porcentaje;
            });
        }
        datalist.push({porcentaje: porcentaje, puntos:valor})
        // console.log("DATO CAMBIADO: ", porcentaje + ' ==> ' + valor);
        // console.log("DATOS: ", datalist);
        this.setState({
            update: datalist
        })
        
    }
    guardarCambios = () => {

        let http_request = new XMLHttpRequest();
        http_request.open("POST", url_tablas, true);

        
        let obj = {
            tipo_peticion: "update",
            user: {
                id_cliente: parseInt(this.state.idCliente),
                kpi_name: 'kpi'+parseInt(this.state.kpi),
                lista: this.state.update
            }
        }
        http_request.send(JSON.stringify(obj));
        http_request.onreadystatechange = () => {
            if (http_request.readyState === XMLHttpRequest.DONE && http_request.status === 200) {
                
                window.M.toast({ html: 'Se han actualizado los puntos' })
                this.props.history.goBack();
            }
        }

    }
    chargeTableKpis = () => {
        let html = []
        let list = this.state.data;

        list.forEach((dato) => {
            html.push(<tr><td className="center  pad-top-porc col s4 offset-s2 "> {parseInt(dato.porcentaje)}% </td>
                <td className="center    col s4">
                    <div className="input-field center-col col s3  ">
                        <input id={"dato_punto_" + parseInt(dato.porcentaje)} percent={dato.porcentaje} onBlur={this.cambioValor} defaultValue={dato.puntos_percentiles} type="text" className=" center validate margin-bot0" />
                    </div>
                </td>
            </tr>);
        });
        return html;
    }

    render() {
        return (
            <div className="App">
                <div className="row">
                    <div className="container margin-top-2p">
                            <ButtonForm text="Guardar" event={this.guardarCambios}></ButtonForm><ButtonBack text="Volver" event={this.props.history.goBack}></ButtonBack>
                        {/* <a className="btn-floating btn-large waves-effect waves-light  teal right margin-bot3p" onClick={this.props.history.goBack}><i className="material-icons right">arrow_back</i></a> */}
                        <table className="tabla-kpis">
                            <thead>
                                <tr>
                                    <th className="center col s4   offset-s2"> Porcentaje</th>
                                    <th className="center  col s4  "> Puntos Percentiles </th>
                                </tr>
                            </thead>
                            <tbody>
                                {this.chargeTableKpis()}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        );
    }

}

export default DetallesKpi