import React, { Component } from 'react';
import _, { toUpper, isEmpty } from 'lodash';
const url_tablas = "https://www.perfect-store.es/serverside/productos.php";

class ListaProductos extends Component {

    constructor(props) {
        super(props);
        this.state = {
            productos: [],
            productosList: [],
            clientes: [],
            familias: [],
            subfamilias: [],
            newFamilia: false,
            newSubfamilia: false,
            busquedaActiva: false,
            selectProductos: null
        };
    }

    componentDidMount() {
        if (document.getElementById("titlePage")) {
            document.getElementById("titlePage").innerHTML = toUpper("lista productos");
        }
        let http_request = new XMLHttpRequest();
        http_request.open("POST", url_tablas, true);
        let obj = {
            tipo_peticion: "",
            user: null
        }
        http_request.send(JSON.stringify(obj));
        http_request.onreadystatechange = () => {
            if (http_request.readyState === XMLHttpRequest.DONE && http_request.status === 200) {
                let data = JSON.parse(http_request.responseText);
                let productosList = _.sortBy(data.productos, o => -o.idProducto, ['asc']);
                // console.log("DATA: ", data);
                this.setState({
                    productos: productosList,
                    productosList: productosList,
                    clientes: data.clientes
                }, () => {
                    // console.log("state cambiado", this.state);
                    const options3 = { dismissible: false };
                    let elem = document.getElementById("borrarProducto");
                    window.M.Modal.init(elem, options3);
                    let elem1 = document.getElementById("nuevoProducto");
                    window.M.Modal.init(elem1, options3);
                    let elem2 = document.getElementById("editarProducto");
                    window.M.Modal.init(elem2, options3);
                });
            }
        }
    }
    componentDidUpdate() {
        window.M.AutoInit();
        const options4 = { dismissible: false };
        let elem = document.getElementById("borrarProducto");
        window.M.Modal.init(elem, options4);
        let elem1 = document.getElementById("nuevoProducto");
        window.M.Modal.init(elem1, options4);
        let elem2 = document.getElementById("editarProducto");
        window.M.Modal.init(elem2, options4);
    }
    clearProductos = () => {


        this.setState({
            productosList: this.state.productos,
            busquedaActiva: false
        }, () => {
            document.getElementById("search_cliente").value = '-1';
            document.getElementById("search_familia").value = '-1';
            document.getElementById("search_subfamilia").value = '-1';
            // window.M.AutoInit();
        });
    }
    buscarProductos = () => {

        let tempFilter = this.state.productos;
        let id_cliente = parseInt(document.getElementById("search_cliente").value);

        let familia = document.getElementById("search_familia").value;
        let subfamilia = document.getElementById("search_subfamilia").value;

        // let palabras = document.getElementById('buscar').value.split(' ')

        // console.log('Prod Result 1: ', tempFilter)
        // console.log('Subfamilia: ', subfamilia)
        if (id_cliente !== '-1') {
            tempFilter = tempFilter.filter(client => { return id_cliente === parseInt(client.id_cliente) })
            // console.log('Prod Result 2: ', tempFilter)
        }

        if (familia !== '-1') {
            tempFilter = tempFilter.filter(client => { return familia.toUpperCase() === client.familia.toUpperCase() })
            // console.log('Prod Result: 3 ', tempFilter)
        }

        if (subfamilia !== '-1') {
            tempFilter = tempFilter.filter(client => { return subfamilia.toUpperCase() === client.subfamilia.toUpperCase() })
            // console.log('Prod Result:4 ', tempFilter)
        }



        // if (palabras[0] === '' && palabras.length > 1) {
        //     palabras.splice(0, 1);
        // }
        // console.log('Palabras: ', palabras)
        // if (palabras) {
        //     for (let i = 0; i < palabras.length; i++) {
        //          tempFilter = tempFilter.filter(ele => {
        //             searchProductos = ' ';
        //             searchProductos = searchProductos.concat(ele.marca + ' ' + ele.EAN13 + ' ' + ele.EAN13 );

        //             // console.log("NOMBRE CONCAT :", searchProductos)
        //             campo = (searchProductos).toUpperCase()
        //             campo = campo.replace(/á/gi, "a");
        //             campo = campo.replace(/é/gi, "e");
        //             campo = campo.replace(/í/gi, "i");
        //             campo = campo.replace(/ó/gi, "o");
        //             campo = campo.replace(/ú/gi, "u");
        //             campo = campo.replace(/à/gi, "a");
        //             campo = campo.replace(/è/gi, "e");
        //             campo = campo.replace(/ì/gi, "i");
        //             campo = campo.replace(/ò/gi, "o");
        //             campo = campo.replace(/ù/gi, "u");
        //             valor = palabras[i].toUpperCase();
        //             valor = valor.replace(/á/gi, "a");
        //             valor = valor.replace(/é/gi, "e");
        //             valor = valor.replace(/í/gi, "i");
        //             valor = valor.replace(/ó/gi, "o");
        //             valor = valor.replace(/ú/gi, "u");
        //             valor = valor.replace(/à/gi, "a");
        //             valor = valor.replace(/è/gi, "e");
        //             valor = valor.replace(/ì/gi, "i");
        //             valor = valor.replace(/ò/gi, "o");
        //             valor = valor.replace(/ù/gi, "u");
        //             return campo.search(valor) > -1
        //         })
        //     }
        //     if (tempFilter.length === 0) {
        //         tempFilter = this.state.productos;
        //     }else{
        //         hayFiltro= true;
        //     }

        //     // console.log('Shops Result: ', shopsFilter)
        //     this.setState({
        //         productosList: tempFilter
        //     });
        // }
        // console.log('Prod Result: ', tempFilter)
        if (tempFilter.length === 0) {
            tempFilter = this.state.productos;
            this.setState({
                productosList: tempFilter
            }, () => {
                window.M.toast({ html: 'No hay resultados con ese filtro' })
            });
        } else {
            this.setState({
                productosList: tempFilter,
                busquedaActiva: true
            });
        }
    }
    activeModalAdd = (e) => {
        document.getElementById("codigo_cliente").value = '-1';
        document.getElementById("codigo").value = "";
        document.getElementById("ean").value = "";
        document.getElementById("familia").value = "";
        document.getElementById("sel_familia").value = "-1";
        document.getElementById("subfamilia").value = "";
        document.getElementById("sel_subfamilia").value = "-1";
        document.getElementById("marca").value = "";
        document.getElementById("nombre").value = "";
        // document.getElementById("facing").value = "";
        document.getElementById("pvp").value = "";
        document.getElementById("tipo").value = "-1";
        // document.getElementById("ranking").value = "";
        // window.M.AutoInit();
        let elem = document.getElementById("nuevoProducto");
        let instance = window.M.Modal.getInstance(elem);
        instance.open();
    }
    activeModalUpdate = (e) => {
        let idproducto = e.currentTarget.getAttribute('id');
        // window.M.AutoInit();
        let familias = [];
        let subfamilias = [];

        this.state.productos.forEach(producto => {
            if (parseInt(producto.idProducto) === parseInt(idproducto)) {
                let clienteVal = producto.id_cliente;
                let familiaVal = producto.familia;
                let registroCliente = _.filter(this.state.clientes, (reg) => {
                    if (reg.cliente.id_cliente === clienteVal) {
                        return reg
                    }
                })
                // console.log("Registro cliente:", registroCliente[0]);
                familias = _.each(registroCliente[0].familias, (reg) => {
                    return reg.familia
                })
                // console.log("Registro cliente:", registroCliente[0]);
                let registroFamilia = _.filter(registroCliente[0].familias, (reg) => {
                    if (reg.familia === familiaVal) {
                        return reg
                    }
                })
                subfamilias = _.each(registroFamilia[0].subfamilias, (reg) => {
                    return reg
                })
                // console.log("CLIENTE: ", producto.id_cliente);
                document.getElementById("edit_codigo_cliente").value = producto.id_cliente;
                document.getElementById("edit_codigo").value = producto.codigo;
                document.getElementById("edit_ean").value = producto.EAN13;
                document.getElementById("edit_familia").value = producto.familia;
                document.getElementById("edit_subfamilia").value = producto.subfamilia;
                document.getElementById("edit_marca").value = producto.marca;
                document.getElementById("edit_nombre").value = producto.nombre;
                // document.getElementById("edit_facing").value = producto.ancho_facing;
                document.getElementById("edit_pvp").value = producto.pvp_recomendado;
                document.getElementById("edit_tipo").value = producto.tipo;
                // document.getElementById("edit_ranking").value = producto.ranking;
                // document.getElementById("edit_canal").value = producto.canal;
                // window.M.FormSelect.init(document.getElementById("edit_canal"));

            }
        });

        this.setState({
            selectProductos: idproducto,
            familias: familias,
            subfamilias: subfamilias,
        }, () => {
            // console.log("State Borrar", this.state);
            let elem = document.getElementById("editarProducto");
            let instance = window.M.Modal.getInstance(elem);
            instance.open();
        });

    }
    activeModalDelete = (e) => {
        let idproducto = e.currentTarget.getAttribute('id')
        // console.log("idproducto: ", idproducto);
        this.setState({
            selectProductos: idproducto
        }, () => {
            // console.log("State Borrar", this.state);
            let elem = document.getElementById("borrarProducto");
            let instance = window.M.Modal.getInstance(elem);
            instance.open();
        });
    }
    altaProducto = () => {
        let familia = ''
        let subfamilia = ''
        let list = this.state.productos;
        let codigo_cliente = document.getElementById("codigo_cliente").value;
        let nombre_cliente = document.getElementById("codigo_cliente").options[document.getElementById("codigo_cliente").selectedIndex].text;
        let codigo = document.getElementById("codigo").value;
        let ean = document.getElementById("ean").value;
        if (this.state.newFamilia) {
            familia = document.getElementById("familia").value.toUpperCase();
        } else {
            familia = document.getElementById("sel_familia").value.toUpperCase();
        }
        if (this.state.newSubfamilia) {
            subfamilia = document.getElementById("subfamilia").value.toUpperCase();
        } else {
            subfamilia = document.getElementById("sel_subfamilia").value.toUpperCase();
        }
        let marca = document.getElementById("marca").value.toUpperCase();
        let nombre = document.getElementById("nombre").value.toUpperCase();
        // let facing = document.getElementById("facing").value;
        let pvp = document.getElementById("pvp").value;
        let tipo = document.getElementById("tipo").value;
        // let ranking = document.getElementById("ranking").value;

        if (codigo_cliente && codigo && ean && familia && subfamilia && marca && pvp && nombre && tipo) {
            let http_request = new XMLHttpRequest();
            http_request.open("POST", url_tablas, true);
            let newproducto = {
                idProducto: '',
                id_cliente: codigo_cliente,
                nombre_cliente: nombre_cliente,
                codigo: codigo,
                EAN13: ean,
                familia: familia,
                subfamilia: subfamilia,
                marca: marca,
                nombre: nombre,
                pvp_recomendado: pvp,
                // ancho_facing: facing,
                tipo: tipo,
                // ranking: ranking
            }
            let obj = {
                tipo_peticion: "insert",
                user: newproducto
            }
            // console.log("OBJETO producto: ", newproducto);
            http_request.send(JSON.stringify(obj));
            http_request.onreadystatechange = () => {
                if (http_request.readyState === XMLHttpRequest.DONE && http_request.status === 200) {
                    let data = JSON.parse(http_request.responseText);
                    // console.log("DATA: ", data);
                    newproducto.idProducto = data.idProducto;
                    list.unshift(newproducto);
                    this.setState({
                        productos: list,
                        productosList: list
                    }, () => {
                        // console.log("state cambiado", this.state);
                        // window.M.AutoInit();
                    });
                }
            }
        } else {
            window.M.toast({ html: 'Faltan campos por rellenar' })
        }
    }
    deleteProducto = () => {
        let lista = this.state.productos;
        let idproducto = this.state.selectProductos;
        let http_request = new XMLHttpRequest();
        http_request.open("POST", url_tablas, true);
        let obj = {
            tipo_peticion: "delete",
            user: {
                idProducto: idproducto
            }
        }
        http_request.send(JSON.stringify(obj));
        http_request.onreadystatechange = () => {
            if (http_request.readyState === XMLHttpRequest.DONE && http_request.status === 200) {
                _.remove(lista, function (currentObject) {
                    return currentObject.idProducto === idproducto;
                });

                this.setState({
                    productos: lista,
                    productosList: lista
                }, () => {
                    window.M.toast({ html: 'La producto ha sido borrada' })
                    // console.log("state cambiado", this.state);
                    // window.M.AutoInit();
                });
            }
        }
    }
    updateProducto = () => {
        let list = this.state.productos;
        let idproducto = this.state.selectProductos;
        let codigo_cliente = document.getElementById("edit_codigo_cliente").value;
        let nombre_cliente = document.getElementById("edit_codigo_cliente").options[document.getElementById("edit_codigo_cliente").selectedIndex].text;
        let codigo = document.getElementById("edit_codigo").value;
        let ean = document.getElementById("edit_ean").value;
        let familia = document.getElementById("edit_familia").value.toUpperCase();;
        let subfamilia = document.getElementById("edit_subfamilia").value.toUpperCase();;
        let marca = document.getElementById("edit_marca").value.toUpperCase();;
        let nombre = document.getElementById("edit_nombre").value.toUpperCase();;
        // let facing = document.getElementById("edit_facing").value;
        let tipo = document.getElementById("edit_tipo").value;
        let pvp = document.getElementById("edit_pvp").value;
        // let ranking = document.getElementById("edit_ranking").value;
        // let idCadena = document.getElementById("edit_cadena").value;
        // let nombre_cadena = document.getElementById("edit_cadena").options[document.getElementById("edit_cadena").selectedIndex].text;
        if (idproducto && codigo_cliente && codigo && ean && familia && subfamilia && marca && pvp && nombre && tipo) {
            let http_request = new XMLHttpRequest();
            http_request.open("POST", url_tablas, true);
            let newproducto = {
                idProducto: idproducto,
                id_cliente: codigo_cliente,
                nombre_cliente: nombre_cliente,
                codigo: codigo,
                EAN13: ean,
                familia: familia,
                subfamilia: subfamilia,
                marca: marca,
                nombre: nombre,
                pvp_recomendado: pvp,
                // ancho_facing: facing,
                tipo: tipo,
                // ranking: ranking
            }

            let obj = {
                tipo_peticion: "update",
                user: newproducto
            }
            http_request.send(JSON.stringify(obj));
            http_request.onreadystatechange = () => {
                if (http_request.readyState === XMLHttpRequest.DONE && http_request.status === 200) {
                    // console.log("DATA: ", data);
                    // console.log("id: ", idproducto);

                    _.remove(list, function (producto) {
                        return producto.idProducto === idproducto;
                    })
                    list.unshift(newproducto);


                    this.setState({
                        productos: list,
                        productosList: list
                    }, () => {
                        // console.log("state cambiado", this.state);
                        window.M.toast({ html: 'El producto ha sido actualizado' })
                        // window.M.AutoInit();
                        let subfamilia = document.getElementById("search_subfamilia").value;
                        if (subfamilia != "-1") {
                            this.buscarProductos();
                        }
                    });
                }
            }
        } else {
            window.M.toast({ html: 'Faltan campos por rellenar' })
        }
    }
    toogleAddFamilia = () => {
        if (this.state.newFamilia) {
            this.setState({
                newFamilia: false,
            })
        } else {
            this.setState({
                newFamilia: true,
            })
        }
    }
    toogleAddSubfamilia = () => {
        if (this.state.newSubfamilia) {
            this.setState({
                newSubfamilia: false,
            })
        } else {
            this.setState({
                newSubfamilia: true,
            })
        }

    }
    chargeFamiliaModalEdit = () => {
        let clienteVal = document.getElementById("edit_codigo_cliente").value;
        let registroCliente = _.filter(this.state.clientes, (reg) => {
            if (reg.cliente.id_cliente === clienteVal) {
                return reg
            }
        })
        // console.log("Registro cliente:", registroCliente[0]);
        let familias = _.each(registroCliente[0].familias, (reg) => {
            return reg.familia

        })
        // console.log("Cargada familias:", familias);

        this.setState({
            familias: familias,
        }, () => {
            // window.M.AutoInit();
            document.getElementById("edit_familia").value = "-1";
            window.M.FormSelect.init(document.getElementById("edit_familia"));
            let elem = document.getElementById("editarProducto");
            let instance = window.M.Modal.getInstance(elem);
            instance.open();
        })
    }
    chargeSubfamiliaModalEdit = () => {
        let clienteVal = document.getElementById("edit_codigo_cliente").value;
        let familiaVal = document.getElementById("edit_familia").value;
        let registroCliente = _.filter(this.state.clientes, (reg) => {
            if (reg.cliente.id_cliente === clienteVal) {
                return reg
            }
        })
        // console.log("Registro cliente:", registroCliente[0]);
        let registroFamilia = _.filter(registroCliente[0].familias, (reg) => {
            if (reg.familia === familiaVal) {
                return reg
            }
        })
        let subfamilias = _.each(registroFamilia[0].subfamilias, (reg) => {
            return reg

        })
        // console.log("Cargada subfamilias:", subfamilias);
        this.setState({
            subfamilias: subfamilias,
        }, () => {
            // window.M.AutoInit();
            document.getElementById("edit_subfamilia").value = "-1";
            window.M.FormSelect.init(document.getElementById("edit_subfamilia"));
            let elem = document.getElementById("editarProducto");
            let instance = window.M.Modal.getInstance(elem);
            instance.open();
        })
    }
    chargeFamiliaModal = () => {
        let clienteVal = document.getElementById("codigo_cliente").value;
        let registroCliente = _.filter(this.state.clientes, (reg) => {
            if (reg.cliente.id_cliente === clienteVal) {
                return reg
            }
        })
        // console.log("Registro cliente:", registroCliente[0]);
        let familias = _.each(registroCliente[0].familias, (reg) => {
            return reg.familia

        })
        // console.log("Cargada familias:", familias);

        this.setState({
            familias: familias,
        }, () => {
            // window.M.AutoInit();
            document.getElementById("sel_familia").value = "-1";
            window.M.FormSelect.init(document.getElementById("sel_familia"));
            let elem = document.getElementById("nuevoProducto");
            let instance = window.M.Modal.getInstance(elem);
            instance.open();
        })
    }
    chargeSubfamiliaModal = () => {
        let clienteVal = document.getElementById("codigo_cliente").value;
        let familiaVal = document.getElementById("sel_familia").value;
        let registroCliente = _.filter(this.state.clientes, (reg) => {
            if (reg.cliente.id_cliente === clienteVal) {
                return reg
            }
        })
        // console.log("Registro cliente:", registroCliente[0]);
        let registroFamilia = _.filter(registroCliente[0].familias, (reg) => {
            if (reg.familia === familiaVal) {
                return reg
            }
        })
        let subfamilias = _.each(registroFamilia[0].subfamilias, (reg) => {
            return reg

        })
        // console.log("Cargada subfamilias:", subfamilias);
        this.setState({
            subfamilias: subfamilias,
        }, () => {
            // window.M.AutoInit();
            document.getElementById("sel_subfamilia").value = "-1";
            window.M.FormSelect.init(document.getElementById("sel_subfamilia"));
            let elem = document.getElementById("nuevoProducto");
            let instance = window.M.Modal.getInstance(elem);
            instance.open();
        })
    }

    chargeFamilia = () => {
        let clienteVal = document.getElementById("search_cliente").value;
        let registroCliente = _.filter(this.state.clientes, (reg) => {
            if (reg.cliente.id_cliente === clienteVal) {
                return reg
            }
        })
        // console.log("Registro cliente:", registroCliente[0]);
        let familias = _.each(registroCliente[0].familias, (reg) => {
            return reg.familia

        })
        // console.log("Cargada familias:", familias);
        this.setState({
            familias: familias,
            subfamilias: [],
        }, () => {
            document.getElementById("search_familia").value = "-1";
            window.M.FormSelect.init(document.getElementById("search_familia"));
            document.getElementById("search_subfamilia").value = "-1";
            window.M.FormSelect.init(document.getElementById("search_subfamilia"));
            // window.M.AutoInit();
        })
    }

    chargeSubfamilia = () => {
        let clienteVal = document.getElementById("search_cliente").value;
        let familiaVal = document.getElementById("search_familia").value;
        let registroCliente = _.filter(this.state.clientes, (reg) => {
            if (reg.cliente.id_cliente === clienteVal) {
                return reg
            }
        })
        // console.log("Registro cliente:", registroCliente[0]);
        let registroFamilia = _.filter(registroCliente[0].familias, (reg) => {
            if (reg.familia === familiaVal) {
                return reg
            }
        })
        let subfamilias = _.each(registroFamilia[0].subfamilias, (reg) => {
            return reg

        })
        // console.log("Cargada subfamilias:", subfamilias);
        this.setState({
            subfamilias: subfamilias,
        }, () => {
            document.getElementById("search_subfamilia").value = "-1";
            window.M.FormSelect.init(document.getElementById("search_subfamilia"));
            // window.M.AutoInit();
        })
    }

    chargeselect = (tipo) => {
        let html1 = []
        let list1 = []
        switch (tipo) {
            case 'clientes':
                // console.log("CLIENTES: ", this.state.clientes);
                list1 = this.state.clientes;
                list1.forEach((reg, index) => {
                    html1.push(<option key={index} value={reg.cliente.id_cliente}>{reg.cliente.nombre}</option>);
                });
                break;
            case 'familias':
                list1 = this.state.familias;
                list1.forEach((familia, index) => {
                    html1.push(<option key={index} value={familia.familia}>{familia.familia}</option>);
                });
                break;
            case 'subfamilias':
                list1 = this.state.subfamilias;
                if (!isEmpty(list1)) {
                    list1.forEach((ele, index) => {
                        html1.push(<option key={index} value={ele}>{ele}</option>);
                    });
                }
                break;
            default:
                break;
        }

        return html1;

    }
    chargeListProductos = () => {
        let html = []
        let list = this.state.productosList

        list = _.sortBy(list, { 'idProducto': 'asc' });

        if (list.length > 0) {
            list.forEach((producto) => {
                html.push(<tr key={producto.idProducto}>
                    <td className="center padding5-5 border-table size-3"> {producto.idProducto} </td>
                    <td className="center padding5-5 border-table size-3"> {producto.nombre_cliente} </td>
                    <td className="center padding5-5 border-table size-3"> {producto.codigo} </td>
                    <td className="center padding5-5 border-table size-3"> {producto.EAN13} </td>
                    <td className="center padding5-5 border-table size-3"> {producto.familia} </td>
                    <td className="center padding5-5 border-table size-3"> {producto.subfamilia} </td>
                    <td className="center padding5-5 border-table size-3"> {producto.marca} </td>
                    <td className="center padding5-5 border-table size-3"> {producto.nombre} </td>
                    {/* <td className="center padding5-5 border-table"> {producto.ancho_facing} </td> */}
                    <td className="center padding5-5 border-table size-3"> {producto.pvp_recomendado} </td>
                    <td className="center padding5-5 border-table size-3"> {producto.tipo} </td>
                    {/* <td className="center padding5-5 border-table"> {producto.ranking} </td> */}
                    <td className="center padding5-5 border-table width-5">
                        <div className=" btn-floating tooltipped margin-btn color-red margin-bot5" data-position="left" data-tooltip="<a href='#' class='btn black-tooltip'>Borrar</a>" id={producto.idProducto} onClick={this.activeModalDelete}><i className="material-icons size-1 white-text">delete</i></div>
                        <div className=" btn-floating margin-btn tooltipped color-blue  margin-bot5" data-position="left" data-tooltip="<a href='#' class='btn black-tooltip'>Editar Productos</a>" id={producto.idProducto} onClick={this.activeModalUpdate}><i className="material-icons size-1 white-text">edit</i></div>
                    </td>
                </tr>);
            });
        } else {
            html.push(<tr key="0">
                <td className="center padding5-5" colSpan="13" >No hay productos</td>
            </tr>);
        }

        return html;
    }

    render() {
        return (
            <div className="App">
                <div className="row ">
                    <div className="container margin-top-2p">

                    </div>

                    <div className="variable-cont">
                        <div className="file-field input-field col s2 left">
                            {/* {this.state.busquedaActiva ? 'btn color-red' : 'btn color-red hide'} */}
                            <div className='btn color-red' onClick={this.clearProductos}>
                                <i className="material-icons">rotate_left</i>
                            </div>
                            <div className="btn" onClick={this.buscarProductos}>
                                <i className="material-icons">search</i>
                            </div>
                            {/* <div className="file-path-wrapper">
                                <input id="buscar" className="file-path validate" type="text" placeholder="Buscar Producto.." />
                            </div> */}
                        </div>
                        <div className="input-field col s2">
                            <select id="search_cliente" onChange={this.chargeFamilia} defaultValue="-1">
                                <option value='-1' disabled >Selecciona el Cliente</option>
                                {this.chargeselect('clientes')}
                            </select>
                        </div>
                        <div className="input-field col s2">
                            <select id="search_familia" onChange={this.chargeSubfamilia} defaultValue="-1">
                                <option value='-1' disabled >Selecciona el Familia</option>
                                {this.chargeselect('familias')}
                            </select>
                        </div>
                        <div className="input-field col s2">
                            <select id="search_subfamilia" defaultValue="-1">
                                <option value='-1' disabled >Selecciona el Subfamilia</option>
                                {this.chargeselect('subfamilias')}
                            </select>
                        </div>
                        <div className="waves-effect waves-light btn right margin-bot3p" onClick={this.activeModalAdd}><i className="material-icons right">add</i>Nuevo Productos</div>
                        <br />
                        <table className="tabla-kpis striped">
                            <thead key="thead" className="">
                                <tr>
                                    <th className="center padding5-5">Id Producto</th>
                                    <th className="center padding5-5">Cliente</th>
                                    <th className="center padding5-5">Codigo</th>
                                    <th className="center padding5-5">EAN13</th>
                                    <th className="center padding5-5">Familia</th>
                                    <th className="center padding5-5">Subfamilia</th>
                                    <th className="center padding5-5">Marca</th>
                                    <th className="center padding5-5">Nombre</th>
                                    {/* <th className="center padding5-5">Ancho Facing</th> */}
                                    <th className="center padding5-5">PVP Rec.</th>
                                    <th className="center padding5-5">Tipo</th>
                                    {/* <th className="center padding5-5">Ranking</th> */}
                                    <th className="center padding5-5">Accion</th>
                                </tr>
                            </thead>
                            <tbody key="tbody">
                                {this.chargeListProductos()}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div id="borrarProducto" className="modal color-yellow">
                    <div className="modal-content">
                        <h4>Borrar producto</h4>
                        <p>Estas seguro que quieres borrar esta producto?</p>
                    </div>
                    <div className="modal-footer color-yellow">
                        <a href="#" className="modal-close waves-effect waves-green btn-flat color-green white-text" onClick={this.deleteProducto}>Sí, Seguro</a>
                        &nbsp; &nbsp;
                        <a href="#" className="modal-close waves-effect waves-green btn-flat color-red white-text">Cancelar</a>
                    </div>
                </div>
                <div id="nuevoProducto" className="modal color-yellow border-rad">
                    <div className="modal-content">
                        <h4>Añadir producto</h4>
                        <form className="col s12">
                            <div className="row">
                                {/* <div className="input-field col s6">
                                    <input id="codigo_cliente" type="text" className="validate" />
                                    <label htmlFor="codigo_cliente">Id Cliente</label>
                                </div> */}
                                <div className="input-field col s6">
                                    <select id="codigo_cliente" onChange={this.chargeFamiliaModal} defaultValue="-1">
                                        <option value='-1' disabled >Selecciona el Cliente</option>
                                        {this.chargeselect('clientes')}
                                    </select>
                                </div>
                                <div className="input-field col s6">
                                    <input id="codigo" type="text" className="validate" />
                                    <label htmlFor="codigo">Codigo</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className={!this.state.newFamilia ? "input-field col s5" : "input-field col s5 hide"}>
                                    <select id="sel_familia" onChange={this.chargeSubfamiliaModal} defaultValue="-1" >
                                        <option value='-1' disabled  >Selecciona la Familia</option>
                                        {this.chargeselect('familias')}
                                    </select>
                                </div>
                                <div className={this.state.newFamilia ? "input-field col s5" : "input-field col s5 hide"} >
                                    <input id="familia" type="text" className="validate" />
                                    <label htmlFor="familia">Familia</label>
                                </div>
                                <a href="#" className="col s1 btn-floating margin-btn color-green margin-bot5" onClick={this.toogleAddFamilia} data-position="bottom" data-tooltip="<a href='#' class='btn black-tooltip'>Agregar Familia</a>" ><i className="material-icons size-1">add</i></a>
                                <div className={!this.state.newSubfamilia ? "input-field col s5" : "input-field col s5 hide"}>
                                    <select id="sel_subfamilia" defaultValue="-1" >
                                        <option value='-1' disabled  >Selecciona la Subfamilia</option>
                                        {this.chargeselect('subfamilias')}
                                    </select>
                                </div>
                                <div className={this.state.newSubfamilia ? "input-field col s5" : "input-field col s5 hide"}>
                                    <input id="subfamilia" type="text" className="validate" />
                                    <label htmlFor="subfamilia">Subfamilia</label>
                                </div>
                                <a href="#" className="col s1 btn-floating tooltipped margin-btn color-green margin-bot5" onClick={this.toogleAddSubfamilia} data-position="bottom" data-tooltip="<a href='#' class='btn black-tooltip'>Agregar Subfamilia</a>" ><i className="material-icons size-1">add</i></a>
                            </div>
                            <div className="row">
                                <div className="input-field col s3">
                                    <input id="ean" type="text" className="validate" />
                                    <label htmlFor="ean">EAN13</label>
                                </div>
                                <div className="input-field col s3">
                                    <input id="marca" type="text" className="validate" />
                                    <label htmlFor="marca">Marca</label>
                                </div>
                                <div className="input-field col s6">
                                    <input id="nombre" type="text" className="validate" />
                                    <label htmlFor="nombre">Nombre</label>
                                </div>


                                {/* <div className="input-field col s6">
                                    <select id="cadena">
                                        <option defaultValue="-1" disabled selected >Selecciona la Cadena</option>
                                        {this.chargeselect("cadenas")}
                                    </select>
                                </div> */}

                            </div>
                            <div className="row">
                                {/* <div className="input-field col s6">
                                    <input id="facing" type="text" className="validate" />
                                    <label htmlFor="facing">Ancho Facing</label>
                                </div> */}
                                <div className="input-field col s6">
                                    <input id="pvp" type="text" className="validate" />
                                    <label htmlFor="pvp">PVP Recomendado</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-field col s6">
                                    <select id="tipo" defaultValue="-1">
                                        <option value="-1" disabled >Selecciona el Tipo</option>
                                        <option value="surtido">Surtido</option>
                                        <option value="clave">Clave</option>
                                        <option value="innovacion">Innovacion</option>
                                    </select>
                                </div>

                                {/* <div className="input-field col s6">
                                    <input id="ranking" type="number" className="validate" />
                                    <label htmlFor="ranking">Ranking</label>
                                </div> */}

                            </div>
                        </form>
                        <div className=" col s12">
                            <a href="#" className="modal-close waves-effect waves-green btn-modal btn-flat color-green white-text right" onClick={this.altaProducto}>Dar de Alta</a>
                            <a href="#" className="modal-close waves-effect waves-green btn-modal btn-flat color-red white-text right">Cancelar</a>
                        </div>
                    </div>
                </div>
                <div id="editarProducto" className="modal color-yellow">
                    <div className="modal-content">
                        <h4>Actualizar Productos</h4>
                        <form className="col s12">
                            <div className="row">
                                {/* <div className="input-field col s6">
                                    <input id="edit_codigo_cliente" type="text" className="validate" />
                                    <label htmlFor="edit_codigo_cliente">Id Cliente</label>
                                </div> */}
                                <div className="input-field col s6">
                                    <select id="edit_codigo_cliente" onChange={this.chargeFamiliaModalEdit}>
                                        <option defaultValue="-1" disabled  >Selecciona el Cliente</option>
                                        {this.chargeselect('clientes')}
                                    </select>
                                </div>
                                <div className="input-field col s6">
                                    <input id="edit_codigo" type="text" className="validate" />
                                    <label htmlFor="edit_codigo">Codigo</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-field col s5">
                                    <select id="edit_familia" onChange={this.chargeSubfamiliaModalEdit} defaultValue="-1" >
                                        <option value='-1' disabled >Selecciona la Familia</option>
                                        {this.chargeselect('familias')}
                                    </select>
                                </div>
                                {/* <div className="input-field col s6">
                                    <input id="edit_familia" type="text" className="validate" />
                                    <label htmlFor="edit_familia">Familia</label>
                                </div> */}

                                <div className="input-field col s5">
                                    <select id="edit_subfamilia" defaultValue="-1" >
                                        <option value='-1' disabled >Selecciona la Subfamilia</option>
                                        {this.chargeselect('subfamilias')}
                                    </select>
                                </div>
                                {/* <div className="input-field col s6">
                                    <input id="edit_subfamilia" type="text" className="validate" />
                                    <label htmlFor="edit_subfamilia">Subfamilia</label>
                                </div> */}
                            </div>
                            <div className="row">
                                <div className="input-field col s4">
                                    <input id="edit_ean" type="text" className="validate" />
                                    <label htmlFor="edit_ean">EAN13</label>
                                </div>
                                <div className="input-field col s4">
                                    <input id="edit_marca" type="text" className="validate" />
                                    <label htmlFor="edit_marca">Marca</label>
                                </div>
                                <div className="input-field col s4">
                                    <input id="edit_nombre" type="text" className="validate" />
                                    <label htmlFor="edit_nombre">Nombre</label>
                                </div>


                                {/* <div className="input-field col s6">
                                    <select id="cadena">
                                        <option defaultValue="-1" disabled selected >Selecciona la Cadena</option>
                                        {this.chargeselect("cadenas")}
                                    </select>
                                </div> */}

                            </div>
                            <div className="row">
                                {/* <div className="input-field col s6">
                                    <input id="edit_facing" type="text" className="validate" />
                                    <label htmlFor="edit_facing">Ancho Facing</label>
                                </div> */}
                                <div className="input-field col s6">
                                    <input id="edit_pvp" type="text" className="validate" />
                                    <label htmlFor="edit_pvp">PVP Recomendado</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-field col s6">
                                    <select id="edit_tipo">
                                        <option defaultValue="-1" disabled>Selecciona el Tipo</option>
                                        <option value="surtido">Surtido</option>
                                        <option value="clave">Clave</option>
                                        <option value="innovacion">Innovacion</option>
                                    </select>
                                </div>
                                {/* <div className="input-field col s6">
                                    <input id="edit_ranking" type="number" className="validate" />
                                    <label htmlFor="edit_ranking">Ranking</label>
                                </div> */}

                            </div>
                        </form>
                        <div className=" col s12">
                            <a href="#" className="modal-close waves-effect waves-green btn-modal btn-flat color-green white-text right" onClick={this.updateProducto}>Actualizar</a>
                            <a href="#" className="modal-close waves-effect waves-green btn-modal btn-flat color-red white-text right">Cancelar</a>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

}

export default ListaProductos