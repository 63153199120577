import React, { Component } from 'react';
import Routes from './Routes';
import { Link, Redirect} from 'react-router-dom';
import logo2 from './logo2.png';
import logo from './logo.png';




class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    window.M.AutoInit();
  }

  isLogin = () => {
    return (localStorage.getItem('login') === 'true')
  };
  
  isAdmin = () => {
    return (localStorage.getItem('Perfil') === 'administrador')
  };

 isCliente = () => {
    return (localStorage.getItem('Perfil') === 'Cliente')
  };

  refresh = () => {
    window.location.reload();
  }

  render() {
    return (
      <div className="row">
        {this.isLogin() && this.isAdmin() ? <div className="card-login">
          <nav className="color-orange">
            <div className="nav-wrapper">
              <ul id="nav-mobile" className="left hide-on-med-and-down">
                <a href="/#" data-target="slide-out" className="brand-logo sidenav-trigger"><img src={logo} alt="logo" width="60px" /></a>
              </ul>
              <span id="titlePage" className="brand-logo center">ADMINISTRACIÓN PERFECT STORE</span> 
            </div>
          </nav>

          <ul id="slide-out" className="sidenav">
            <li>
              <div className="user-view">
                <div className="background">
                </div>
                <a href="#user"><img src={logo2} alt="logo2" className="item-center logo-menu" /></a>
                <h4 className="center">PERFECT STORE</h4>
              </div>
            </li>
            <li><Link to='/lista-tiendas' className="color-blue-mod white-text color"><i className="material-icons white-text ">store</i>TIENDAS</Link></li>
            <li><Link to='/lista-clientes' className="color-yellow white-text color"><i className="material-icons white-text ">person</i>CLIENTES</Link></li> 
            <li><Link to='/lista-productos' className="color-aquamarine white-text color"><i className="material-icons white-text ">widgets</i>PRODUCTOS</Link></li>
            <li><Link to='/surtidos' className="color-green white-text color"><i className="material-icons white-text ">shopping_cart</i>SURTIDO</Link></li>
            <li><Link to='/lista-gpvs' className="color-red-mod white-text color"><i className="material-icons white-text ">directions_walk</i>GPV</Link></li>
            <li><Link to='/rutas' className="color-purple white-text color"><i className="material-icons white-text ">directions_car</i>RUTAS</Link></li>
            <br />
            <br />
            <li>
              <Link to='/logOut' onClick={this.refresh} className="color-black white-text color"><i className="material-icons white-text ">exit_to_app</i>Salir</Link>
            </li>
          </ul>
        </div> : ''}
        <Routes />
        {!this.isLogin() ? <Redirect to="/login" /> : this.isCliente() ? <Redirect to="/InformeCliente" /> : ''}
      </div>);
  }

}



export default App;
