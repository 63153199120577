import React, { Component } from 'react';


class ButtonForm extends Component {

    constructor(props) {
        super(props);
        // No llames this.setState() aquí!
        this.state = {
            id: props.id, 
            event: props.event,
            text: props.text
        };
    }

    render() {
        return (
            <a href="#" className="waves-effect waves-light btn right"  id={"button_form_"+this.state.id} onClick={this.state.event} >{this.state.text}</a>
        );
    }
}
export default ButtonForm