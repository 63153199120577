import React, { Component } from 'react';


class ButtonBack extends Component {

    constructor(props) {
        super(props);
        // No llames this.setState() aquí!
        this.state = {
            event: props.event,
            text: props.text
        };
    }
    componentDidMount(){
        window.M.AutoInit();
    }
    chargeEvent = () => {
        window.M.AutoInit();
        this.state.event();
    }
    render() {
        return (
            <a href="#" className="waves-effect waves-light left btn-floating blue tooltipped" onClick={this.chargeEvent} data-position="top" data-tooltip={"<a href='#' class='btn black-tooltip'>"+this.state.text+"</a>"}><i className="material-icons size-1">arrow_back</i></a>
        );
    }
}
export default ButtonBack