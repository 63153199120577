import React, { Component } from 'react';
import Modal from 'react-modal';
import _, { toUpper, isNull, isEmpty } from 'lodash';
const url_tablas = "https://www.perfect-store.es/serverside/rutas.php";
let options = {};

const customModal = {
    content: {
        position: 'absolute',
        top: '30px',
        left: '30px',
        right: '30px',
        bottom: '30px',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        background: 'rgb(255 248 233)',
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
        borderRadius: '6px',
        outline: 'none',
        padding: '0px'
    }
};

const customModalGPV = {
    content: {
        position: 'absolute',
        top: '155px',
        left: '155px',
        right: '155px',
        bottom: '155px',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        background: 'rgb(255 200 0)',
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
        borderRadius: '6px',
        outline: 'none',
        padding: '0px'
    }
};

const customModalDelete = {
    content: {
        position: 'absolute',
        top: '30px',
        left: '25vw',
        right: '25vw',
        bottom: '36vw',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        background: 'rgb(255 200 0)',
        overflow: 'none',
        WebkitOverflowScrolling: 'touch',
        borderRadius: '6px',
        outline: 'none',
        padding: '10px'
    }
};

const customModalDetail = {
    content: {
        position: 'absolute',
        top: '30px',
        left: '18vw',
        right: '18vw',
        bottom: '2vw',
        border: '1px solid #ccc',
        background: 'rgb(255 200 0)',
        overflow: 'none',
        WebkitOverflowScrolling: 'touch',
        borderRadius: '4px',
        outline: 'none',
        padding: '10px'
    }
};

class Rutas extends Component {

    constructor(props) {
        super(props);
        this.state = {
            rutas: [],
            shops_ruta_test: [],
            tiendas: [],
            tiendasList: [],
            gpvs: [],
            cadenas: [],
            clientesVsTiendas: [],
            clientes: [],
            ensenyas: [],
            copyTiendas: [],
            selectedGpvs: [],
            checkAllRutas: false,
            sync: false,
            objectRuta: [],
            selectRuta: null,
            modalRutaIsOpen: false,
            modalGpvIsOpen: false,
            modalDelete: false,
            modalDetailIsOpen: false
        };

    }

    openModalDelete = (e) => {
        let id = e.currentTarget.id;
        // console.log("ID A Editar", id)
        this.setState({
            selectRuta: id
        }, () => {
            this.setDeleteIsOpen(true);
        });
    }

    setDeleteIsOpen = (state) => {
        this.setState({
            modalDelete: state,
        });
    }

    setIsOpen = (state) => {
        this.setState({
            modalRutaIsOpen: state,
            checkAllRutas: false,
        });
    }

    setDetailIsOpen = (state) => {
        this.setState({
            modalDetailIsOpen: state,
        });
    }

    openModal = (e) => {
        let id = e.currentTarget.id;
        // console.log("ID A Editar", id)
        if (id) {
            this.setState({
                selectRuta: id
            }, () => {
                this.setIsOpen(true);
            });
        } else {
            this.setState({
                selectRuta: null
            }, () => {
                this.setIsOpen(true);
            });
        }
    }

    setGpvIsOpen = (state, id) => {
        this.setState({
            modalGpvIsOpen: state,
        });
    }

    openModalGpv = (e) => {
        this.setGpvIsOpen(true);
    }

    afterOpenModal = () => {
        // references are now sync'd and can be accessed. 
        window.M.AutoInit();
        let id = this.state.selectRuta;
        if (id) {
            let tiendas = [];
            let ruta = [];
            let selGpvs = [];
            // console.log("RUTA LIST: ", typeof this.state.rutas[0].establecimientos)
            ruta = this.state.rutas.filter(ele => {
                return parseInt(ele.idRuta) === parseInt(id)
            })
            // console.log("RUTA LIST: ", ruta[0].establecimientos);
            // let group_ensenyas = _.groupBy(this.state.shops_ruta_test, "idEnsenya");
            if (!Array.isArray(ruta[0].establecimientos)) {
                tiendas = this.state.tiendas.filter(ele => {
                    // console.log("valor: ", ele.idEst + ' | ' + ruta.establecimientos + ' => ');
                    // console.log("match: ", parseInt(ruta.establecimientos) === parseInt(ele.idEst));
                    return ruta[0].establecimientos === ele.idEst
                })
            } else {
                tiendas = this.state.tiendas.filter(ele => {
                    // console.log("match: ", ele.idEst + ' | ' + ruta[0].establecimientos + ' => ' + _.includes(ruta[0].establecimientos, ele.idEst));
                    return _.includes(ruta[0].establecimientos, ele.idEst)
                })
            }
            if (!Array.isArray(ruta[0].gpvs)) {
                selGpvs.push(ruta[0].gpvs)
            } else {
                selGpvs = ruta[0].gpvs
            }

            this.setState({
                shops_ruta_test: tiendas,
                selectedGpvs: selGpvs,
                objectRuta: ruta[0]
            }, () => {
                // console.log('AFTER STATE: ', this.state);
            });
        } else {
            this.setState({
                shops_ruta_test: [],
                selectedGpvs: [],
                objectRuta: null,
            }, () => {
                // console.log('AFTER STATE: ', this.state);
            });
        }
    }

    closeDeleteModal = () => {
        this.setState({
            selectRuta: null,
        }, () => {
            this.setDeleteIsOpen(false);
        });
    }

    openModalDetail = (e) => {
        let id = e.currentTarget.id;
        // console.log("ID A Editar", id)
        if (id) {
            this.setState({
                selectRuta: id
            }, () => {
                this.setDetailIsOpen(true);
            });
        }
    }

    closeModalDetail = () => {
        this.setState({
            selectRuta: null,
        }, () => {
            this.setDetailIsOpen(false);
        });
    }

    closeGpvModal = () => {
        this.setGpvIsOpen(false);
    }

    closeModal = () => {
        this.setState({
            selectRuta: null,
            shops_ruta_test: [],
            selectedGpvs: [],
            objectRuta: null,
        }, () => {
            this.setIsOpen(false);
        });
    }

    componentDidMount() {
        if (document.getElementById("titlePage")) {
            document.getElementById("titlePage").innerHTML = toUpper("Lista rutas");
        }
        let http_request = new XMLHttpRequest();
        http_request.open("POST", url_tablas, true);
        let obj = {
            tipo_peticion: "",
            user: null
        }
        http_request.send(JSON.stringify(obj));
        http_request.onreadystatechange = () => {
            if (http_request.readyState === XMLHttpRequest.DONE && http_request.status === 200) {
                let data = JSON.parse(http_request.responseText);
                // console.log("DATA: ", data);

                this.setState({
                    rutas: data.rutas,
                    tiendas: data.establecimientos,
                    tiendasList: data.establecimientos,
                    gpvs: data.gpvs,
                    cadenas: data.cadenas,
                    ensenyas: data.ensenyas,
                    clientesVsTiendas: data.clientesVsTienda,
                    clientes: data.clientes,
                    sync: true,
                }, () => {
                    // console.log("state cambiado", this.state);
                    // console.log("RUTA CALLBACk: ", this.state.rutas[0].establecimientos);
                    window.M.AutoInit();
                });
            }
        }
    }

    guardarRuta = () => {
        let id = this.state.selectRuta
        // console.log("id guardar ruta");
        if (!isNull(id)) {
            if (this.state.selectedGpvs.length > 0) {
                let http_request = new XMLHttpRequest();
                let idRuta = this.state.selectRuta;
                let ests = [];
                let estsId = [];
                _.each(this.state.shops_ruta_test, (est) => {
                    est.clientes = [];
                    _.each(this.state.clientesVsTiendas, (relacion) => {
                        if (relacion.idEst === est.idEst && relacion.idRuta === id) {
                            est.clientes.push(relacion.id_cliente);
                        
                        }
                    });
                    ests.push(est);
                    estsId.push(est.idEst);
                });
            
                http_request.open("POST", url_tablas, true);

                let ruta = {
                    idRuta: idRuta,
                    nombre: document.getElementById("name_ruta").value,
                    descripcion: document.getElementById("descripcion").value,
                    gpvs: this.state.selectedGpvs,
                    establecimientos: ests
                }    
                console.log("RUTA UPLOAD: ", ruta);
                let obj = {
                    tipo_peticion: "update",
                    user: ruta
                }
                http_request.send(JSON.stringify(obj));
                http_request.onreadystatechange = () => {
                    if (http_request.readyState === XMLHttpRequest.DONE && http_request.status === 200) {
                        // console.log("UPDATE: ", ruta);
                        let newRutas = this.state.rutas
                        newRutas = _.remove(newRutas, function (ruta) {
                            // console.log("RUTA ", ruta.idRuta + ' || ID: ' + idRuta)
                            return ruta.idRuta !== idRuta;
                        });
                        let ruta2 = {
                            idRuta: idRuta,
                            nombre: document.getElementById("name_ruta").value,
                            descripcion: document.getElementById("descripcion").value,
                            gpvs: this.state.selectedGpvs,
                            establecimientos: estsId
                        }    
                        // console.log("UPDATE RUTAS: ", newRutas);
                        newRutas.push(ruta2);
                        this.setState({
                            rutas: newRutas,
                            selectRuta: null,
                            selectGpvs:[]
                        }, () => {
                            // console.log("state cambiado", this.state);
                            this.closeModal()
                            window.M.AutoInit();
                        });
                    }
                }
            } else {
                window.M.toast({ html: '❌ No has seleccionado gpvs' });
            }
        } else {
            if (this.state.selectedGpvs.length > 0) {
                let http_request = new XMLHttpRequest();
                let ests = [];
                let estsId = [];
                _.each(this.state.shops_ruta_test, (est) => {
                    est.clientes = [];
                    _.each(this.state.clientesVsTiendas, (relacion) => {
                        if (relacion.idEst === est.idEst && relacion.idRuta === id) {
                            est.clientes.push(relacion.id_cliente);
                        }
                    });
                    ests.push(est);
                    estsId.push(est.idEst);
                });


                http_request.open("POST", url_tablas, true);
                let ruta = {
                    nombre: document.getElementById("name_ruta").value,
                    descripcion: document.getElementById("descripcion").value,
                    gpvs: this.state.selectedGpvs,
                    establecimientos: ests
                }
                console.log("RUTA UPLOAD: ", ruta);
                let obj = {
                    tipo_peticion: "insert",
                    user: ruta
                }
                http_request.send(JSON.stringify(obj));
                http_request.onreadystatechange = () => {
                    if (http_request.readyState === XMLHttpRequest.DONE && http_request.status === 200) {
                        let data = JSON.parse(http_request.responseText);
                        // console.log("INSERT: ", ruta);
                        let ruta2 = {
                            nombre: document.getElementById("name_ruta").value,
                            descripcion: document.getElementById("descripcion").value,
                            gpvs: this.state.selectedGpvs,
                            establecimientos: estsId
                        }    
                        ruta2.idRuta = data.id.toString();
                        let newRutas = this.state.rutas
                        newRutas.push(ruta2);
                        this.setState({
                            rutas: newRutas,
                            selectGpvs: []
                        }, () => {
                            // console.log("state cambiado", this.state);
                            this.closeModal()
                            window.M.AutoInit();
                        });
                    }
                }
            } else {
                window.M.toast({ html: '❌ No has seleccionado gpvs' });
            }

        }
    }

    componentDidUpdate(prevState) {
        // console.log("DidUpdate");
        //inicializo los tooltip
        // window.M.Tooltip.init(document.querySelectorAll('.tooltipped'), null);
        //inicializo el modal de editar usuario
        let elemsfil1 = document.querySelectorAll('#conf-edit');
        let instancesfil1 = window.M.Modal.init(elemsfil1, options);
        if (instancesfil1[0] !== undefined) {
            window.M.Modal.getInstance(instancesfil1[0].el);
            instancesfil1[0].options.dismissible = false;
        }
    }

    createCollapsibleByShop = (shops) => {
        let listShops = shops;
        let resultado = [];

        for (let i = 0; i < listShops.length; i++) {
            if (i !== 0) {
                resultado.push(<hr key={'hr-' + i} className="col s12 l12 xl12" />);
            }
            resultado.push(
                <div className="row" key={'shop-' + i} id="containershop">
                    <div className="containernameshop col s10 l10 xl10 vertical-center" >
                        {listShops[i].nombre}
                    </div>
                    <div className="col s2 l2 xl2 vertical-center" id="containercheckbox">
                        <label className="labelcheck">
                            <input type="checkbox" className="filled-in checkcenter" idest={listShops[i].idEst} defaultChecked={false} />
                            <span></span>
                        </label>
                        <i className="material-icons" id="sendshop" onClick={() => { this.addShopToRoute(listShops[i]) }}>arrow_forward</i>
                    </div>
                </div>
            )
        }
        return resultado;
    }

    limpiarCheckbox = () => {
        let checkbox = document.getElementsByClassName('labelcheck')
        for (let i = 0; i < checkbox.length; i++) {
            checkbox[i].childNodes[0].checked = false;
        }
        if (this.state.checkAllRutas === false) {
            let checkbox2 = document.getElementsByClassName('labelcheck2')
            for (let i = 0; i < checkbox2.length; i++) {
                checkbox2[i].childNodes[0].checked = false;
            }
        } else {
            let checkbox2 = document.getElementsByClassName('labelcheck2')
            for (let i = 0; i < checkbox2.length; i++) {
                checkbox2[i].childNodes[0].checked = true;
            }
        }

    }

    getRuta = (campo) => {
        if (!isEmpty(this.state.selectRuta)) {
            var result = [];
            var rutas = this.state.rutas;
            result = _.find(rutas, { 'idRuta': this.state.selectRuta })
            // console.log("GET Ruta: ", result)
            return result[campo];
        }
    }

    getCadena = (id) => {
        var result = [];
        var cadenas = this.state.cadenas;

        result = _.find(cadenas, { 'idCadena': id })
        // console.log("GET CADENA: ",result.nombre)
        return result.nombre;
    }

    getEnsenya = (id) => {
        var result = [];
        var ensenyas = this.state.ensenyas;

        result = _.find(ensenyas, { 'idEnsenya': id })
        // console.log("GET ENSENYA: ",result.nombre)
        return result.nombre;
    }

    getSelectValues = (select) => {
        var result = [];
        var options = select && select.options;
        var opt;

        for (var i = 0; i < options.length; i++) {
            opt = options[i];

            if (opt.selected) {
                result.push(opt.value || opt.text);
            }
        }
        return result;
    }

    removeClientsInSelectShops = () => {
        let checkbox2 = document.getElementsByClassName('labelcheck2')
        let tiendas = [];
        let registros = [];
        let idRuta = this.state.selectRuta
        let select = document.getElementById('chips_cliente');
        let valores = this.getSelectValues(select);
        // console.log("VALORES: ", valores);
        for (let i = 0; i < checkbox2.length; i++) {
            if (checkbox2[i].childNodes[0].checked === true) {
                tiendas.push(checkbox2[i].childNodes[0].getAttribute('idest'));
            }
        }
        // console.log("TIENDAS: ", tiendas);
        if (!isEmpty(valores)) {
            _.each(tiendas, (est) => {
                _.each(valores, (valor) => {
                    let find = _.find(this.state.clientesVsTiendas, { 'idEst': est, 'id_cliente': valor, 'idRuta': idRuta });
                    if (!isEmpty(find)) {
                        let obj = {
                            idEst: est,
                            id_cliente: valor,
                            idRuta: idRuta,
                        }
                        registros.push(obj);
                    }
                });
            });
            let newList = this.state.clientesVsTiendas
            _.each(registros, (reg) => {
                _.remove(newList, { idRuta: reg.idRuta, idEst: reg.idEst, id_cliente: reg.id_cliente });
            });
            this.setState({
                clientesVsTiendas: newList
            }, () => {
                window.M.AutoInit();
            });
            // console.log("Registros Finales: ", registros);

            // let http_request = new XMLHttpRequest();
            // http_request.open("POST", url_tablas, true);
            // let objeto = {
            //     tiendas: registros
            // }
            // let obj = {
            //     tipo_peticion: "removeClienteEst",
            //     user: objeto
            // }
            // http_request.send(JSON.stringify(obj));
            // http_request.onreadystatechange = () => {
            //     if (http_request.readyState === XMLHttpRequest.DONE && http_request.status === 200) {
            //         // console.log("DATA: ", data);
            //         let newList = this.state.clientesVsTiendas
            //         _.each(registros, (reg) => {
            //             _.remove(newList, { idRuta: reg.idRuta, idEst: reg.idEst, id_cliente: reg.id_cliente });
            //         });
            //         this.setState({
            //             clientesVsTiendas: newList
            //         }, () => {
            //             window.M.AutoInit();
            //         });
            //     }
            // }
        } else {
            window.M.toast({ html: '❌ Falta seleccionar los clientes que quieres quitar' })
        }
    }

    addClientsInSelectShops = () => {
        let checkbox2 = document.getElementsByClassName('labelcheck2')
        let tiendas = [];
        let registros = [];
        let idRuta = this.state.selectRuta
        let select = document.getElementById('chips_cliente');
        let valores = this.getSelectValues(select);
        // console.log("VALORES: ", valores);
        for (let i = 0; i < checkbox2.length; i++) {
            if (checkbox2[i].childNodes[0].checked === true) {
                tiendas.push(checkbox2[i].childNodes[0].getAttribute('idest'));
            }
        }
        // console.log("TIENDAS: ", tiendas);
        if (!isEmpty(valores)) {
            _.each(tiendas, (est) => {
                _.each(valores, (valor) => {
                    let find = _.find(this.state.clientesVsTiendas, { 'idEst': est, 'id_cliente': valor, 'idRuta': idRuta });
                    if (isEmpty(find)) {
                        let obj = {
                            idEst: est,
                            id_cliente: valor,
                            idRuta: idRuta,
                        }
                        registros.push(obj);
                    }
                });
            });

            let newList = this.state.clientesVsTiendas
            _.each(registros, (reg) => {
                newList.push(reg);
            });
            this.setState({
                clientesVsTiendas: newList
            }, () => {
                window.M.AutoInit();
            });

            // console.log("Registros Finales: ", registros);

            // let http_request = new XMLHttpRequest();
            // http_request.open("POST", url_tablas, true);
            // let objeto = {
            //     tiendas: registros
            // }
            // let obj = {
            //     tipo_peticion: "insertClienteEst",
            //     user: objeto
            // }
            // http_request.send(JSON.stringify(obj));
            // http_request.onreadystatechange = () => {
            //     if (http_request.readyState === XMLHttpRequest.DONE && http_request.status === 200) {
            //         // console.log("DATA: ", data);
            //         let newList = this.state.clientesVsTiendas
            //         _.each(registros, (reg) => {
            //             newList.push(reg);
            //         });
            //         this.setState({
            //             clientesVsTiendas: newList
            //         }, () => {
            //             window.M.AutoInit();
            //         });
            //     }
            // }
        } else {
            window.M.toast({ html: '❌ Falta seleccionar los clientes que quieres añadir' })
        }
    }

    addClientInShop = (est) => {

        let idRuta = this.state.selectRuta
        let select = document.getElementById('chips_cliente');
        let valores = this.getSelectValues(select);
        // console.log("VALORES: ", valores);
        let registros = [];
        if (!isEmpty(valores)) {
            _.each(valores, (valor) => {
                let find = _.find(this.state.clientesVsTiendas, { 'idEst': est, 'id_cliente': valor, 'idRuta': idRuta });
                if (isEmpty(find)) {
                    let obj = {
                        idEst: est,
                        id_cliente: valor,
                        idRuta: idRuta,
                    }
                    registros.push(obj);
                }
            });
            let newList = this.state.clientesVsTiendas
            _.each(registros, (reg) => {
                newList.push(reg);
            });
            this.setState({
                clientesVsTiendas: newList
            }, () => {
                window.M.AutoInit();
            });

            // let http_request = new XMLHttpRequest();
            // http_request.open("POST", url_tablas, true);
            // let objeto = {
            //     tiendas: registros
            // }
            // let obj = {
            //     tipo_peticion: "insertClienteEst",
            //     user: objeto
            // }
            // http_request.send(JSON.stringify(obj));
            // http_request.onreadystatechange = () => {
            //     if (http_request.readyState === XMLHttpRequest.DONE && http_request.status === 200) {
            //         // console.log("DATA: ", data);
            //         let newList = this.state.clientesVsTiendas
            //         _.each(registros, (reg) => {
            //             newList.push(reg);
            //         });
            //         this.setState({
            //             clientesVsTiendas: newList
            //         }, () => {
            //             window.M.AutoInit();
            //         });
            //     }
            // }
        } else {
            window.M.toast({ html: '❌ Falta seleccionar los clientes que quieres añadir' })
        }

    }

    removeClientOnShop = (est, cliente) => {
        let idRuta = this.state.selectRuta
        let newList = this.state.clientesVsTiendas
        _.remove(newList, { idRuta: idRuta, idEst: est, id_cliente: cliente });
        this.setState({
            clientesVsTiendas: newList
        }, () => {
            window.M.AutoInit();
        });
        // let http_request = new XMLHttpRequest();
        // http_request.open("POST", url_tablas, true);
        // let objeto = {
        //     tiendas: [{ idEst: est, id_cliente: cliente, idRuta: idRuta }]
        // }
        // let obj = {
        //     tipo_peticion: "removeClienteEst",
        //     user: objeto
        // }
        // http_request.send(JSON.stringify(obj));
        // http_request.onreadystatechange = () => {
        //     if (http_request.readyState === XMLHttpRequest.DONE && http_request.status === 200) {
        //         // console.log("DATA: ", data);
        //         let newList = this.state.clientesVsTiendas
        //         _.remove(newList, { idRuta: idRuta, idEst: est, id_cliente: cliente });
        //         this.setState({
        //             clientesVsTiendas: newList
        //         }, () => {
        //             window.M.AutoInit();
        //         });
        //     }
        // }
    }

    createChips = (shop) => {

        let chips = [];
        let clientes = this.state.clientes
        // console.log("SHOP : ", shop);
        let clientesEst = _.filter(this.state.clientesVsTiendas, (temp) => {
            return temp.idRuta === this.state.selectRuta && temp.idEst === shop.idEst
        })
        // console.log("Clientes: ", clientes);

        _.each(clientesEst, (relacion) => {
            let temp = _.filter(clientes, (client) => { return relacion.id_cliente === client.id_cliente })
            // console.log("TEMP: ", temp);
            chips.push(
                <div className="chip">
                    <span>{temp[0].nombre}  </span><i className="material-icons icon-adap tiny" onClick={() => { this.removeClientOnShop(shop.idEst, temp[0].id_cliente) }} >clear</i>
                </div>
            );
        })

        return chips
    }

    createChipsDetail = (shop) => {

        let chips = [];
        let clientes = this.state.clientes
        // console.log("SHOP : ", shop);
        let clientesEst = _.filter(this.state.clientesVsTiendas, (temp) => {
            return temp.idRuta === this.state.selectRuta && temp.idEst === shop.idEst
        })
        // console.log("Clientes: ", clientes);

        _.each(clientesEst, (relacion) => {
            let temp = _.filter(clientes, (client) => { return relacion.id_cliente === client.id_cliente })
            // console.log("TEMP: ", temp);
            chips.push(
                <div className="chip-detail orange">
                    <span>{temp[0].nombre}</span>
                </div>
            );
        })

        return chips
    }
    checkAll = () => {
        this.setState({
            checkAllRutas: !this.state.checkAllRutas
        }, () => {
            window.M.AutoInit();
        });
    }
    createCollapsibleRutaActual = (shops, i) => {
        let resultado = [];
        let checked = this.state.checkAllRutas
        let hr = <hr className="col s12 l12 xl12" />;
        if (i !== 0) {
            resultado.push(<hr className="col s12 l12 xl12" />);
        }
        resultado.push(
            <div className="row" key={'div' + i} id="containershop">
                <div className="col s2 l2 xl2 vertical-center" id="containercheckbox">
                    <i className="material-icons micro" id="shopinruta" onClick={() => { this.removeShopInRoute(shops) }} >arrow_back</i>
                    <label className="labelcheck2">
                        <input type="checkbox" className="filled-in checkcenter2" idest={shops.idEst} defaultChecked={false} />
                        <span></span>
                    </label>
                </div>
                <div className="containernameshop col s10 l10 xl10 vertical-center" onClick={this.selectShopByRuta}>
                    {shops.nombre}
                </div>
                <div className="col s12 l12 xl12">
                    {this.createChips(shops)}
                    <div className="chip bg-orange-ps white-text" idest={shops.idEst} onClick={() => { this.addClientInShop(shops.idEst) }} >
                        <i className="material-icons icon-adap tiny">add</i>
                    </div>
                </div>
            </div>
        );
        return resultado;
    }

    getShopByIdEst = (idEst) => {
        return _.find(this.state.tiendas, ['idEst', idEst]);
    }

    addShopToRoute = (shop) => {
        let shops_in_route = this.state.shops_ruta_test;
        if (shop !== null) {
            shops_in_route.push(shop);
        } else {
            let elements_checkbox = document.getElementsByClassName("checkcenter");
            for (let i = 0; i < elements_checkbox.length; i++) {
                elements_checkbox[i].setAttribute("checked", false);
            }
            for (let i = 0; i < elements_checkbox.length; i++) {
                if (elements_checkbox[i].checked) {
                    shops_in_route.push(this.getShopByIdEst(elements_checkbox[i].getAttribute('idest')));
                }
            }

        }
        this.setState({
            shops_ruta_test: shops_in_route
        }, () => {
            // let elements_send = document.getElementsByClassName("shopinruta");
            // let elements_label = document.getElementsByClassName("labelcheck2");

            // for (let i = 0; i < elements_send.length; i++) {
            //     if (elements_send[i] !== undefined)
            //         elements_send[i].className = "shopinruta material-icons left";
            //     if (elements_label[i] !== undefined)
            //         elements_label[i].className = "labelcheck2 hide";
            //     //elements_checkbox[i].setAttribute("checked", "checked");
            // }
        });
    }

    removeAllShopsInRoute = () => {
        this.setState({
            shops_ruta_test: []
        });
    }

    removeShopInRoute = (shop) => {
        // console.log('Shops: ', shop)
        let shops_in_route = this.state.shops_ruta_test;
        if (shop !== null) {
            _.remove(shops_in_route, ['idEst', shop.idEst]);
        } else {
            let elements_checkbox = document.getElementsByClassName("checkcenter2");

            for (let i = 0; i < elements_checkbox.length; i++) {
                if (elements_checkbox[i].checked) {
                    let shop_to_remove = this.getShopByIdEst(elements_checkbox[i].getAttribute('idest'));
                    if (shop_to_remove !== undefined) {
                        _.remove(shops_in_route, ['idEst', shop_to_remove.idEst]);
                    }
                    // elements_checkbox[i].click();
                }
            }
        }
        this.setState({
            shops_ruta_test: shops_in_route
        }, () => {
            // let elements_send = document.getElementsByClassName("shopinruta");
            // let elements_label = document.getElementsByClassName("labelcheck2");

            // for (let i = 0; i < elements_send.length; i++) {
            //     if (elements_send[i] !== undefined)
            //         elements_send[i].className = "shopinruta material-icons left";
            //     if (elements_label[i] !== undefined)
            //         elements_label[i].className = "labelcheck2 hide";
            //     //elements_checkbox[i].setAttribute("checked", "checked");
            // }
        });
    }

    deleteRuta = () => {
        let http_request = new XMLHttpRequest();
        let idRuta = this.state.selectRuta;
        http_request.open("POST", url_tablas, true);
        let ruta = {
            idRuta: idRuta,
        }
        let obj = {
            tipo_peticion: "delete",
            user: ruta
        }
        http_request.send(JSON.stringify(obj));
        http_request.onreadystatechange = () => {
            if (http_request.readyState === XMLHttpRequest.DONE && http_request.status === 200) {
                let newRutas = this.state.rutas
                _.remove(newRutas, function (currentObject) {
                    return currentObject.idRuta === idRuta;
                });
                this.setState({
                    rutas: newRutas,
                }, () => {
                    // console.log("state cambiado", this.state);
                    this.closeDeleteModal()
                    window.M.AutoInit();
                });
            }
        }
    }

    buscarTienda = () => {
        let tiendasFilter = []
        let tempFilter = this.state.tiendas
        let campo = ''
        let valor = ''
        let searchTienda = ''
        let palabras = document.getElementById('buscar').value.split(' ')
        if (palabras[0] === '' && palabras.length > 1) {
            palabras.splice(0, 1);
        }

        if (document.getElementById('buscar').value.length >= 1) {
            for (let i = 0; i < palabras.length; i++) {
                tiendasFilter = tempFilter.filter(ele => {
                    searchTienda = ' ';
                    _.each(ele.nombre, ele2 => {
                        searchTienda = searchTienda.concat(ele2.nombre + ' ' + ele2.poblacion);
                    })
                    // console.log("NOMBRE CONCAT :", searchTienda)
                    campo = (ele.nombre + ' ' + searchTienda).toUpperCase()
                    campo = campo.replace(/á/gi, "a");
                    campo = campo.replace(/é/gi, "e");
                    campo = campo.replace(/í/gi, "i");
                    campo = campo.replace(/ó/gi, "o");
                    campo = campo.replace(/ú/gi, "u");
                    campo = campo.replace(/à/gi, "a");
                    campo = campo.replace(/è/gi, "e");
                    campo = campo.replace(/ì/gi, "i");
                    campo = campo.replace(/ò/gi, "o");
                    campo = campo.replace(/ù/gi, "u");
                    valor = palabras[i].toUpperCase();
                    valor = valor.replace(/á/gi, "a");
                    valor = valor.replace(/é/gi, "e");
                    valor = valor.replace(/í/gi, "i");
                    valor = valor.replace(/ó/gi, "o");
                    valor = valor.replace(/ú/gi, "u");
                    valor = valor.replace(/à/gi, "a");
                    valor = valor.replace(/è/gi, "e");
                    valor = valor.replace(/ì/gi, "i");
                    valor = valor.replace(/ò/gi, "o");
                    valor = valor.replace(/ù/gi, "u");
                    return campo.search(valor) > -1
                })
                tempFilter = tiendasFilter;
            }
            if (tiendasFilter.length === 0) {
                tiendasFilter = this.state.tiendas;
            }

            // console.log('Shops Result: ', shopsFilter)
            this.setState({
                tiendasList: tiendasFilter
            });
        }
        // console.log('Palabras: ', palabras)
        if (palabras[0] === '') {
            tiendasFilter = this.state.tiendas;
            this.setState({
                tiendasList: tiendasFilter
            });
        }
    }

    createListByRutaActual = () => {
        let resultado = [];
        let count = 0;

        _.forEach(this.state.shops_ruta_test, (element, key) => {
            // let ensenya = this.state.ensenyas.filter(ele => {
            //     return ele.idEnsenya === key
            // })
            count++;
            resultado.push(
                <li key={'rutaA-' + count}>
                    {/* <div className="collapsible-header  listaruta"><b>{ensenya[0].ensenya}</b><span className="new badge amber" data-badge-caption="">{element.length} </span></div>
                    <div className="collapsible-body" id="bodylisttienda">   // console.log("ELEMENTO EN RUTA", element)*/}
                    {this.createCollapsibleRutaActual(element, key)}
                    {this.limpiarCheckbox()}
                    {/* </div> */}
                </li>
            );

        });
        return resultado;
    }

    //-------------------------------------------------------------
    /**
     * Crea la fila de 6 gpv dentro del modal de "cambiar gpv"
     */
    createRowListGpv = () => {
        let resultado = [];
        let gpvs = this.state.gpvs;
        let array_6 = [];

        for (let i = 0; i < gpvs.length; i++) {

            array_6.push(gpvs[i]);
            if (array_6.length === 6) {
                resultado.push(
                    <div className="row border-bottom" key={'row-' + i}>
                        {this.createListGpv(array_6)}
                    </div>
                );
                array_6 = [];
            }
            if (i === gpvs.length - 1 && gpvs.length % 6 !== 0) {
                resultado.push(
                    <div className="row border-bottom" key={'row2-' + i}>
                        {this.createListGpv(array_6)}
                    </div>
                );
                array_6 = [];
            }
        }
        return resultado;
    }

    modifiGpvinRoute = () => {

        let elements_checkbox = document.getElementsByClassName("checkboxgpv");
        let array_checked = [];
        for (let i = 0; i < elements_checkbox.length; i++) {
            if (elements_checkbox[i].checked) {
                array_checked.push(elements_checkbox[i]);
            }
        }
        let objGPV = []
        array_checked.map((ele) => {
            objGPV.push(ele.getAttribute('id'));
        })

        // console.log(objGPV);
        this.setState({
            selectedGpvs: objGPV
        }, () => {
            this.closeGpvModal();
            // console.log('Cambio de state: ', this.state);
        })
        // console.log(array_checked);
    }

    createListGpvsActual = () => {
        let resultado = [];
        let gpvs = this.state.gpvs;
        let selectGpvs = this.state.selectedGpvs;
        for (let i = 0; i < gpvs.length; i++) {
            if (selectGpvs) {
                // console.log('Entra en condicion 1');
                if (selectGpvs.length > 1) {
                    // console.log('Entra en condicion 2');
                    let res = _.find(selectGpvs, function (o) {
                        return gpvs[i].idGpv === o;
                    })
                    if (res) {
                        resultado.push(<span className="pad-rig-15"> {gpvs[i].nombre} {gpvs[i].apellido1} </span>);
                    };

                } else {
                    // console.log('Entra en condicion 3');
                    if (gpvs[i].idGpv === selectGpvs[0]) {
                        resultado.push(<span className="pad-rig-15"> {gpvs[i].nombre} {gpvs[i].apellido1} </span>);
                    };
                }
            }
        }
        return resultado;
    }

    //-------------------------------------------------------------
    /**
     * @param gpvs_6
     * recibe como parametro 6 gpvs, crea un div con su checkbox
     */
    createListGpv = (gpvs_6) => {
        let resultado = [];
        let isInSelect = false
        let selectGpvs = this.state.selectedGpvs;
        // console.log('GPS LISTA: ', gpvs_6)



        for (let i = 0; i < gpvs_6.length; i++) {
            if (selectGpvs) {
                // console.log('Entra en condicion 1');
                if (selectGpvs.length > 1) {
                    // console.log('Entra en condicion 2');
                    let res = _.find(selectGpvs, function (o) {
                        return gpvs_6[i].idGpv === o;
                    })
                    isInSelect = res;

                } else {
                    // console.log('Entra en condicion 3');
                    isInSelect = gpvs_6[i].idGpv === selectGpvs[0];

                }

            }
            resultado.push(
                <div id="containergpvcheck" className="col s2 m2 l2 xl2" key={'check-' + i}>
                    <div className="row ">
                        <div className="col s2 m2 l2 xl2 ">
                            <label className="labelcheck3">
                                <input type="checkbox" className="filled-in checkboxgpv" defaultChecked={isInSelect} onChange={this.toogleListGpv} id={gpvs_6[i].idGpv} data-name={gpvs_6[i].nombre + ' ' + gpvs_6[i].apellido1} />
                                <span></span>
                            </label>
                        </div>
                        <div className="col s10 m10 l10 xl10 left-align">
                            <span>{gpvs_6[i].nombre} {gpvs_6[i].apellido1}</span>
                        </div>
                    </div>
                </div>
            );
        }
        return resultado;
    }

    createCollapsibleByEnsenya = () => {

        let resultado = [];
        let count = 0;

        window.M.AutoInit();
        let group_ensenyas = _.groupBy(this.state.tiendasList, "idEnsenya");
        // console.log('ARRAY CENTROS', this.state.shopsCopy)
        // console.log('ARRAY ENSENYAS', this.state.ensenyas)
        _.forEach(group_ensenyas, (element, key) => {
            let ensenya = this.state.ensenyas.filter(ele => {
                return ele.idEnsenya === key
            })
            // console.log('ENSENYA ELEGIDA', ensenya)
            // console.log('ENSENYA Nombre', ensenya.ensenya)
            for (let i = 0; i < this.state.shops_ruta_test.length; i++) {
                _.remove(element, ['idEst', this.state.shops_ruta_test[i].idEst]);
            }
            count++;
            resultado.push(
                <li key={'li-' + count}>
                    <div className="collapsible-header listaruta"><b>{ensenya[0].nombre}</b><span className="new badge amber" data-badge-caption="">{element.length} </span>{/*<i className='material-icons micro'>local_grocery_store</i>*/}</div>
                    <div className="collapsible-body" id="bodylisttienda">
                        {/* {// console.log("tiendas Enseña:", ensenya)} */}
                        {this.createCollapsibleByShop(element)}
                        {this.limpiarCheckbox()}
                    </div>
                </li>
            );

        });

        return resultado;

    }

    chargeselect = (tipo) => {
        let html1 = []
        let list1 = []
        switch (tipo) {
            case 'clientes':
                list1 = this.state.clientes;
                list1.forEach((cliente, index) => {
                    html1.push(<option key={index} value={cliente.id_cliente}>{cliente.nombre}</option>);
                });
                break;
        }

        return html1;

    }

    detailListRutaActual = () => {
        let resultado = [];
        let count = 0;

        _.forEach(this.state.shops_ruta_test, (element, key) => {
            // let ensenya = this.state.ensenyas.filter(ele => {
            //     return ele.idEnsenya === key
            // })
            // console.log("ELEMENT: ", element);
            count++;
            resultado.push(
                <li className="collection-item-est" key={'rutaDetail-' + count}>
                    <div className="row">

                        <div className="col s5">
                            <b>Cadena: </b> {this.getCadena(element.idCadena)}
                        </div>
                        <div className="col s5">
                            <b>Enseña: </b> {this.getEnsenya(element.idEnsenya)}
                        </div>
                        <div className="col s2">
                            <b>Id: </b> {element.idEst}
                        </div>


                        <div className="col s12"><b>Nombre: </b>{element.nombre}</div>

                        <div className="col s12">
                            <b>Clientes</b>
                            <hr></hr>
                            {this.createChipsDetail(element)}
                        </div>
                    </div>
                </li>
            );

        });
        return resultado;
    }

    listaDetailGpv = () => {
        let resultado = [];
        let gpvs = this.state.gpvs;
        let selectGpvs = this.state.selectedGpvs;
        for (let i = 0; i < gpvs.length; i++) {
            if (selectGpvs) {
                // console.log('Entra en condicion 1');
                if (selectGpvs.length > 1) {
                    // console.log('Entra en condicion 2');
                    let res = _.find(selectGpvs, function (o) {
                        return gpvs[i].idGpv === o;
                    })
                    if (res) {
                        resultado.push(<span className="pad-rig-15"> {gpvs[i].nombre} {gpvs[i].apellido1} </span>);
                    };

                } else {
                    // console.log('Entra en condicion 3');
                    if (gpvs[i].idGpv === selectGpvs[0]) {
                        resultado.push(<span className="pad-rig-15"> {gpvs[i].nombre} {gpvs[i].apellido1} </span>);
                    };
                }
            }
        }
        return resultado;
    }

    chargeListRutas = () => {

        let html = []
        let list = this.state.rutas
        list = _.sortBy(list, [function (o) { return parseInt(o.idRuta); }]);

        if (list.length > 0) {
            list.forEach((ruta) => {
                html.push(<tr key={'ruta-' + ruta.idRuta}>
                    <td className="center padding5-5 border-table"> {ruta.idRuta} </td>
                    <td className="center padding5-5 border-table"> {ruta.nombre} </td>
                    <td className="center padding5-5 border-table"> {ruta.descripcion} </td>

                    <td className="center padding5-5 border-table">
                        <div className=" btn-floating tooltipped margin-btn color-red " data-position="left" data-tooltip="<a href='#' class='btn black-tooltip'>Borrar</a>" id={ruta.idRuta} onClick={this.openModalDelete}><i className="material-icons size-1 white-text">delete</i></div>
                        <div className=" btn-floating margin-btn tooltipped color-blue " data-position="left" data-tooltip="<a href='#' class='btn black-tooltip'>Gestionar Ruta</a>" id={ruta.idRuta} onClick={this.openModal}><i className="material-icons size-1 white-text">edit</i></div>
                        <div className=" btn-floating margin-btn tooltipped color-yellow " data-position="left" data-tooltip="<a href='#' class='btn black-tooltip'>Ver Ruta</a>" id={ruta.idRuta} onClick={this.openModalDetail}><i className="material-icons size-1 white-text">remove_red_eye</i></div>
                    </td>
                </tr>);
            });
        } else {
            html.push(<tr key="0">
                <td className="center padding5-5" colSpan="13" >No hay rutas</td>
            </tr>);
        }

        return html;
    }

    render() {
        // console.log("RENDER")



        if (!this.state.sync) {
            return null;
        } else {
            return (
                <div id="ventana" className="App">

                    <div className="row ">
                        <div className="container margin-top-2p">

                        </div>
                        <div className="variable-cont">
                            <div className="waves-effect waves-light btn right margin-bot3p" onClick={this.openModal}><i className="material-icons right">add</i>Nueva Ruta</div>
                            <br />
                            <table className="tabla-kpis striped">
                                <thead key="thead" className="">
                                    <tr>
                                        <th className="center padding5-5">Id Ruta</th>
                                        <th className="center padding5-5">Nombre</th>
                                        <th className="center padding5-5">Descripcion</th>

                                        <th className="center padding5-5">Accion</th>
                                    </tr>
                                </thead>
                                <tbody key="tbody">
                                    {this.chargeListRutas()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <Modal isOpen={this.state.modalRutaIsOpen}
                        onAfterOpen={this.afterOpenModal}
                        onRequestClose={this.closeModal}
                        contentLabel="Example Modal"
                        ariaHideApp={false}
                        style={customModal} >
                        <div className="row">
                            <div className="col s12">
                                <div className="col s4 center">
                                    <h6 className=" margin-bot-rem">DATOS RUTA</h6>
                                    <div className="input-field col s6">
                                        <input id="name_ruta" type="text" defaultValue={this.state.objectRuta ? this.state.objectRuta.nombre : null} className="validate" />
                                        <label htmlFor="name_ruta" className={this.state.objectRuta ? 'active' : null} >Nombre Ruta</label>
                                    </div>
                                    <div className="input-field col s6">
                                        <input id="descripcion" type="text" defaultValue={this.state.objectRuta ? this.state.objectRuta.descripcion : null} className="validate" />
                                        <label htmlFor="descripcion" className={this.state.objectRuta ? 'active' : null} >Descripcion</label>
                                    </div>
                                </div>
                                <div className="col s4 center">
                                    <h6 className=" margin-bot-rem">GESTIONAR GPVS</h6>
                                    <a href="#" className="waves-effect waves-light btn modal-trigger" onClick={this.openModalGpv} >Gpvs</a>

                                </div>
                                <div className="col s4 center">
                                    <h6 className=" margin-bot-rem">GPV ASIGNADO</h6>
                                    <div id="contentGpvs" className="">
                                        <p className="collection" id="collectionGpvs">
                                            {this.createListGpvsActual()}
                                        </p>
                                    </div>
                                </div>

                            </div>
                            <div className="col s12">


                                <div className="col s5 center">
                                    <div className="row" id="containertittlecentros">
                                        <div className="col s12 m12 l12 xl12 left-align " id="divtittlecentros ">
                                            <h6 className=" margin-bot-rem tittlecard " id="tittlecentros ">BUSQUEDA DE CENTROS</h6>
                                        </div>
                                    </div>
                                    <div id="cardcentrosbuscados" className="card">
                                        <div className="margin-top-rem-02  file-field input-field padding-10">
                                            <div className="btn" onClick={this.buscarTienda}>

                                                <i className="material-icons">search</i>
                                            </div>
                                            <div className="file-path-wrapper">
                                                <input id="buscar" className="file-path validate" type="text" placeholder="Buscar.." />
                                            </div>
                                        </div>
                                        <div id="contentcentros" className="card-content ">

                                            <ul className="collapsible" id="collapsiblerutas">
                                                {this.createCollapsibleByEnsenya()}
                                            </ul>
                                        </div>
                                    </div>

                                </div>

                                <div className="margin-top10p  col s2 center">
                                    <br></br> {/*Magico BR*/}
                                    <br></br> {/*Magico BR*/}
                                    <button className="waves-effect waves-light btn-large btn-large-change  light-blue lighten-1" onClick={() => { this.addShopToRoute(null) }}>
                                        <i className="material-icons ">arrow_forward</i>
                                    </button>
                                    <br></br> {/*Magico BR*/}
                                    <br></br> {/*Magico BR*/}
                                    <button className="waves-effect waves-light btn-large btn-large-change  light-blue lighten-1" onClick={() => { this.removeShopInRoute(null) }}>
                                        <i className="material-icons">arrow_back </i>
                                    </button>
                                    <br></br> {/*Magico BR*/}
                                    <br></br> {/*Magico BR*/}
                                    <button className="waves-effect waves-light btn-large btn-large-change  red darken-1" onClick={this.removeAllShopsInRoute}>
                                        <i className="material-icons">clear</i>
                                    </button>

                                </div>
                                <div className="col s5 center">
                                    <div className="row" id="containertittlecentros">
                                        <div className="col s12 m12 l12 xl12" id="divtittlecentros">

                                            <div className="col s4 left-align padding0">
                                                <h6 className=" margin-bot-rem">RUTA ACTUAL</h6>
                                            </div>
                                        </div>
                                    </div>
                                    <div id="cardcentrosbuscados" className="card">
                                        <div className="input-field col s9 margin-bot30">
                                            <select id="chips_cliente" multiple>
                                                {this.chargeselect('clientes')}
                                            </select>
                                            <label htmlFor="chips_cliente">Selecciona los Clientes</label>
                                        </div>
                                        <div className="input-field col s1 width-7 ">
                                            <a href="#" id="button-add-clientes" className="btn btn-floating tooltipped" data-position="top" data-tooltip="<a href='#' class='btn black-tooltip'>Agregar Clientes</a>" onClick={this.addClientsInSelectShops}> <i className="material-icons size-1">add</i></a>
                                        </div>
                                        <div className="input-field col s1 width-7">
                                            <a href="#" id="button-remove-clientes" className="btn btn-floating color-red tooltipped" data-position="top" data-tooltip="<a href='#' class='btn black-tooltip'>Quitar Clientes</a>" onClick={this.removeClientsInSelectShops}> <i className="material-icons size-1">remove</i></a>
                                        </div>
                                        <div className="input-field col s1 ">
                                            {this.state.checkAllRutas ?
                                                <a href="#" id="button-select-all" className="btn btn-floating color-blue tooltipped" data-position="top" data-tooltip="<a href='#' class='btn black-tooltip'>Deseleccionar Ruta</a>" onClick={this.checkAll}> <i className="material-icons size-1">playlist_add_check</i></a>
                                                :
                                                <a href="#" id="button-select-all" className="btn btn-floating color-blue tooltipped" data-position="top" data-tooltip="<a href='#' class='btn black-tooltip'>Selecciona toda Ruta</a>" onClick={this.checkAll}> <i className="material-icons size-1">playlist_add_check</i></a>
                                            }
                                        </div>
                                        <div id="contentcentros2" className="card-content-ruta col s12">

                                            <ul className="collapsible" id="collapsiblerutas2">
                                                {this.createListByRutaActual()}
                                            </ul>

                                        </div>

                                    </div>

                                </div>

                            </div>

                        </div>
                        <button href="#!" className=" btn waves-effect margin-5-5 waves-green right" onClick={this.guardarRuta}>Guardar</button>
                        <button href="#!" className=" btn waves-effect margin-5-5 color-red right" onClick={this.closeModal}>Volver</button>
                    </Modal>
                    <Modal isOpen={this.state.modalGpvIsOpen}
                        onRequestClose={this.closeGpvModal}
                        contentLabel="Example Modal"
                        ariaHideApp={false}
                        style={customModalGPV}>
                        <div className="row">
                            <div className="col s12 margin-5-5">
                                <h4>Seleccionar Gpvs</h4>
                                <br />
                                {this.createRowListGpv()}
                            </div>
                        </div>
                        <button href="#!" className=" btn waves-effect margin-5-5 waves-green right" onClick={this.modifiGpvinRoute}>Guardar</button>
                        <button href="#!" className=" btn waves-effect margin-5-5 color-red right" onClick={this.closeGpvModal}>Volver</button>
                    </Modal>
                    <Modal isOpen={this.state.modalDelete}
                        onRequestClose={this.closeDeleteModal}
                        contentLabel="Delete Modal"
                        ariaHideApp={false}
                        style={customModalDelete}>
                        <div className="row">
                            <div className="col s12 margin-5-5">
                                <h4>Seguro que quieres borrar la ruta?</h4>
                            </div>
                        </div>
                        <button href="#!" className=" btn waves-effect margin-5-5 waves-green right" onClick={this.deleteRuta}>Si</button>
                        <button href="#!" className=" btn waves-effect margin-5-5 color-red right" onClick={this.closeDeleteModal}>No</button>
                    </Modal>
                    <Modal isOpen={this.state.modalDetailIsOpen}
                        onRequestClose={this.closeModalDetail}
                        onAfterOpen={this.afterOpenModal}
                        contentLabel="Detalle Modal"
                        ariaHideApp={false}
                        style={customModalDetail}>
                        <div className="row">
                            <div className="col s12 margin-5-5">
                                <div className="col s6 ">
                                    <h5 className=" margin-bot-rem">Id: {this.state.selectRuta} | {this.getRuta('nombre')}</h5>
                                    <p><b>Descripcion: </b>{this.getRuta('descripcion')}</p>
                                </div>
                                <div className="col s6 ">
                                    <h5 className=" margin-bot-rem">GPVS</h5>
                                    <div id="contentGpvs" className="">
                                        <p className="collection" id="collectionGpvs">
                                            {this.listaDetailGpv()}
                                        </p>
                                    </div>
                                </div>
                                <div className="col s12 margin-5-5">
                                    <h5 className=" margin-bot-rem">{this.state.shops_ruta_test.length} TIENDAS EN LA RUTA</h5>
                                    <div id="contentRuta" className="card-content-est">
                                        <ul className="collection-est" id="collection">
                                            {this.detailListRutaActual()}
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Modal>
                </div>
            );
        }

    }
}

export default Rutas