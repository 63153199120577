import React, { Component } from 'react';
import Modal from 'react-modal';
import ButtonForm from './Elementos/ButtonForm';
import _, { toUpper, isEmpty } from 'lodash';
const url_tablas = "https://www.perfect-store.es/serverside/surtidos.php";
let options = {};
const customModalDelete = {

    content: {
        position: 'absolute',
        top: '9%',
        left: '9%',
        right: '9%',
        bottom: '43%',
        boxShadow: '0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)',
        background: '#fff',
        overflow: 'none',
        WebkitOverflowScrolling: 'touch',
        borderRadius: '6px',
        outline: 'none',
        padding: '10px'
    }
};
let count_tr = 0;
class Surtidos extends Component {

    constructor(props) {
        super(props);
        this.state = {
            products: [],
            productsFilter: [],
            productsCopy: [],
            cadenas: [],
            cadenasCopy: [],
            clientes: [],
            ensenyas: [],
            ensenyasCopy: [],
            familias: [],
            familiasCopy: [],
            surtidoCliente: [],
            surtidoClienteEnsenya: [],
            selected: "",
            cadenaSelect: "-1",
            dataCopy: [],
            idClienteSelected: "-1",
            idEnsenyaSelected: "-1",
            familiaSelected: "-1",
            dataFiltered: [],
            productClicked: {
                nombre: "",
                ean: "",
                codigo_cliente: ""
            },
            checkAllFamilias: false,
            sync: false,
            keyClicked: 0,
            firstTime: false,
            search: "",
            modalFilter: true
        };
    }
    componentDidMount() {
        if (document.getElementById("titlePage")) {
            document.getElementById("titlePage").innerHTML = toUpper("Surtidos");
        }
        let http_request = new XMLHttpRequest();
        http_request.open("GET", url_tablas, true);
        http_request.send(null);

        http_request.onreadystatechange = () => {
            if (http_request.readyState === XMLHttpRequest.DONE && http_request.status === 200) {
                let allprod = JSON.parse(http_request.responseText);
                //console.log("ALLDATOS: ", allprod);
                this.setState({
                    products: allprod.productos,
                    productsCopy: allprod.productos,
                    surtidoCliente: allprod.surtido,
                    clientes: allprod.clientes,
                    cadenas: allprod.cadenas,
                    cadenasCopy: allprod.cadenas,
                    ensenyasCopy: allprod.ensenyas,
                    sync: true
                }, () => {
                    //console.log("Cambio de state Mount: ", this.state);
                    window.M.AutoInit();
                });
            }
        }
    }
    createOptionSelect = (options, key) => {
        let result = [];

        result.push(
            <option value="-1" disabled key="150">Selecciona</option>
        );

        switch (key) {
            case 'familias':
                
                for (let i = 0; i < options.length; i++) {
                    result.push(
                        this.state.checkAllFamilias ? <option value={options[i]} key={i} selected >{options[i]}</option> : <option value={options[i]} key={i}>{options[i]}</option>
                    );
                }

                break;
            case 'clientes':
                for (let i = 0; i < options.length; i++) {
                    result.push(
                        <option value={options[i].id_cliente} key={i}>{options[i].nombre}</option>
                    );
                }

                break;
            case 'cadenas':
                for (let i = 0; i < options.length; i++) {
                    result.push(
                        <option value={options[i].idCadena} key={i}>{options[i].nombre}</option>
                    );
                }

                break;
            case 'ensenyas':
                //console.log("OPTIONS ENSENYAS: ", options);
                //console.log("OPTIONS Cadena Sel: ", this.state.cadenaSelect);
                if (this.state.cadenaSelect > 0) {
                    let ensenyas = _.filter(options, (ele) => {
                        return ele.idCadena === this.state.cadenaSelect
                    })
                    //console.log("OPTIONS filter ENSENYAS: ", ensenyas);
                    for (let i = 0; i < ensenyas.length; i++) {
                        result.push(
                            <option value={ensenyas[i].idEnsenya} key={i}>{ensenyas[i].nombre}</option>
                        );
                    }
                    ;
                }
                break;
            default:
                break;
        }

        return result;
    }
    numeroReferencias = (elements) => {
        let count = 0;
        let ensenya = this.state.idEnsenyaSelected

        _.each(this.state.surtidoCliente, function (a) {
            _.each(elements, function (o) {
                if (a.idProducto === o.idProducto && a.idEnsenya === ensenya) {
                    count++;
                }
            })
        })

        ////console.log("Numero de Referencias: ", count);
        return count
    }
    addToSurtido = (event) => {
        let codigo = event.target.getAttribute("idprod");
        let peticion = "";
        let ensenya = this.state.idEnsenyaSelected;
        let surtido = _.filter(this.state.surtidoCliente, function (o) { return o.idEnsenya === ensenya });
        let surtidoGlobal = _.filter(this.state.surtidoCliente, function (o) { return o.idEnsenya !== ensenya });
        let obj_prod = []
        let elementsListOut = document.getElementsByClassName('checkoutsurtido')
        let elementsListIn = document.getElementsByClassName('checkinsurtido')


        //console.log("ELEMENTS OUT: ", elementsListOut.length)
        //console.log("ELEMENTS IN : ", elementsListIn.length)
        //console.log("ELEMENTS TR : ", element_tr.length)
        //console.log("ENSEÑA : ", ensenya)
        //console.log("SUTIDO Principal: ", surtido)





        for (let i = 0; i < elementsListIn.length; i++) {
            if (elementsListIn[i].getAttribute("idprod") === codigo) {
                if (elementsListIn[i].className.indexOf("hide") !== -1) {
                    // elementsListOut[i].classList.add("hide");
                    // elementsListIn[i].classList.remove("hide")
                    // element_tr[i].classList.add("addsurtido");
                    //console.log("IDPROD TR ADD: ", element_tr[i].getAttribute("idprod"))
                    //console.log("I: ", i);
                    //console.log("ELEMENTS OUT: ", elementsListOut[i])
                    //console.log("ELEMENTS IN : ", elementsListIn[i])
                    //console.log("ELEMENTS TR : ", element_tr[i])
                    peticion = 'insert';
                    obj_prod = {
                        idEnsenya: ensenya,
                        idProducto: codigo,
                        codigo_cliente: elementsListOut[i].codigo_cliente
                    }

                    //console.log("PETICION INSERT BBDD: ", obj_prod)
                    let addSurtido = {
                        id: "",
                        idProducto: codigo,
                        idEnsenya: ensenya,
                        codigo_cliente: null
                    }
                    surtido.push(addSurtido);
                } else {
                    // elementsListOut[i].classList.remove("hide");
                    // elementsListIn[i].classList.add("hide")
                    // element_tr[i].classList.remove("addsurtido");
                    //console.log("IDPROD TR DEL: ", element_tr[i].getAttribute("idprod"))
                    //console.log("I: ", i)
                    //console.log("ELEMENTS OUT: ", elementsListOut[i])
                    //console.log("ELEMENTS IN : ", elementsListIn[i])
                    //console.log("ELEMENTS TR : ", element_tr[i])
                    peticion = 'delete';
                    obj_prod = {
                        idEnsenya: ensenya,
                        idProducto: codigo,
                        codigo_cliente: elementsListIn[i].codigo_cliente
                    }
                    //console.log("PETICION DELETE BBDD: ", obj_prod)

                    _.remove(surtido, function (currentObject) { return currentObject.idProducto === codigo && currentObject.idEnsenya === ensenya; });
                }
            }

        }

        _.each(surtido, function (producto) {
            surtidoGlobal.push(producto)
        })
        //console.log("SUTIDO ACTUALIZADO: ", surtido)
        //console.log("SUTIDO GLOBAL ACTUALIZADO: ", surtidoGlobal)
        //console.log("Lista Productos PREPARADOS:", productList);

        let obj_to_server = {
            tipo_peticion: peticion,
            user: obj_prod
        };

        //console.log("SERVER OBJECT:", obj_to_server);

        let httprequest = new XMLHttpRequest();
        httprequest.open('POST', url_tablas, true);
        httprequest.send(JSON.stringify(obj_to_server));//parametros para 
        httprequest.onreadystatechange = () => {
            let response = JSON.parse(httprequest.responseText);
            ////console.log("response", response);
            if (response.error === "") {
                this.setState({
                    surtidoCliente: surtidoGlobal
                }, () => { });
            }
            else {
                //console.log("ERROR BBDD: ", response.error);
            }
        }

        //event.target.className = "material-icons hide";
        //element.classList.remove("hide");        
    }
    //-------------------------------------------------------------
    selectCliente = (e) => {
        let id = e.target.value;

        let productos = [];
        let familias = [];

        productos = this.state.products.filter(function (item) {
            return item.id_cliente === id;
        })
        _.forEach(productos, function (p) {
            familias.push(p.familia);
        });
        // console.log("FAMILIAS RESULTADO: ", familias);

        familias = familias.filter(function (item, index, array) {
            return array.indexOf(item) === index;
        })
        // console.log("FAMILIAS SIN DUPLICADO RESULTADO: ", familias);

        this.setState({
            idClienteSelected: id,
            familias: familias,
        }, () => {
            document.getElementById("selectcadenas").value = "-1";
            window.M.FormSelect.init(document.getElementById("selectcadenas"));
            document.getElementById("selectensenyas").value = "-1";
            window.M.FormSelect.init(document.getElementById("selectensenyas"));
            document.getElementById("selectfamilias").value = "-1";
            window.M.FormSelect.init(document.getElementById("selectfamilias"));
            //console.log("Cambio de state SelectCliente", this.state.selectCliente)
        })
    }
    selectCadena = (e) => {
        let id = e.target.value;
        let ensenyas = [];
        ensenyas = _.filter(this.state.ensenyasCopy, function (p) {
            return id === p.idCadena;
        });
        //console.log("ensenyas RESULTADO: ", ensenyas);
        this.setState({
            cadenaSelect: id,
            ensenyas: ensenyas,
        }, () => {
            //console.log("Cambio de state SelectCadena ", this.state.cadenaSelect)
        })
    }
    buscarSurtido = () => {
        let allprod = this.state.products;
        let options = document.getElementById("selectfamilias").options;
        let selectedOptions = [];
        let selectedValues = [];

        for (let i = 0; i < options.length; i++) {
            if (options[i].selected && options[i].value !== '-1') {
                selectedOptions.push(options[i].value);
                selectedValues.push(options[i].value.toLowerCase());
            }
        }
        //console.log("Familias: ", selectedValues);
        //console.log("PRODS FILTROS: ", allprod);
        let selectEnsenya = this.state.idEnsenyaSelected
        let selectCliente = this.state.idClienteSelected
        if (!isEmpty(selectCliente)) {
            let filterProducts = allprod.filter(elem => {
                return parseInt(elem.id_cliente) === parseInt(selectCliente);
            });

            if (!isEmpty(selectEnsenya)) {
                if (selectEnsenya !== "") {

                    filterProducts = filterProducts.filter(elem => {
                        // console.log("LLEGA A EL FILTRO");
                        let item1 = elem.familia && elem.familia.toLowerCase();
                        // console.log("ITEM 1: ", item1);
                        if (selectedValues.indexOf(item1) !== -1) {
                            return true;
                        } else {
                            return false;
                        }
                    });

                    //console.log("ENSENYA", selectEnsenya);
                    this.setState({
                        productsCopy: filterProducts,
                        productsFilter: filterProducts,
                        familiaSelected: '-1',
                        modalFilter: false
                    });
                } else {
                    this.setState({
                        productsCopy: [],
                        productsFilter: [],
                    });
                    window.M.toast({ html: 'Selecciona una Enseña para mostrar el catálogo de surtido 1', classes: 'rounded red' });
                }
            } else {
                window.M.toast({ html: 'Selecciona una Enseña para mostrar el catálogo de surtido 2', classes: 'rounded red' });
            }
        } else {

            document.getElementById("selectcadenas").value = "-1";
            document.getElementById("selectensenyas").value = "-1";
            document.getElementById("selectfamilias").value = "-1";
            window.M.AutoInit();
            window.M.toast({ html: 'Selecciona una Cliente para mostrar el catálogo de surtido 3', classes: 'rounded red' });
        }
    }
    getSelectedEnsenya = (e) => {
        let selectEnsenya = e.target.value;
        document.getElementById("selectfamilias").value = -1
        this.setState({
            productsCopy: [],
            productsFilter: [],
            idEnsenyaSelected: selectEnsenya,
        });
    }
    afterOpenModal = () => {
        document.getElementById("selectclientes").value = this.state.idClienteSelected;
        window.M.FormSelect.init(document.getElementById("selectclientes"));
        document.getElementById("selectcadenas").value = this.state.cadenaSelect;
        window.M.FormSelect.init(document.getElementById("selectcadenas"));
        document.getElementById("selectensenyas").value = this.state.idEnsenyaSelected;
        window.M.FormSelect.init(document.getElementById("selectensenyas"));
        document.getElementById("selectfamilias").value = this.state.familiaSelected;
        window.M.FormSelect.init(document.getElementById("selectfamilias"));
        window.M.AutoInit();
    }
    openModalFilter = () => {
        this.setState({
            modalFilter: true,
            checkAllFamilias:false
        });
    }
    closeModalFilter = () => {
        this.setState({
            modalFilter: false
        });
    }
    componentDidUpdate() {
        window.M.AutoInit();
        //inicializo los tooltip
        window.M.Tooltip.init(document.querySelectorAll('.tooltipped'), null);
        //incializo el selector
        let elemselector1 = document.querySelectorAll("[tipo=cadenas]");
        window.M.FormSelect.init(elemselector1);
        let elemselector2 = document.querySelectorAll("[tipo=ensenyas]");
        window.M.FormSelect.init(elemselector2);
        let elemselector3 = document.querySelectorAll("[tipo=familias]");
        window.M.FormSelect.init(elemselector3);
        //inicializo collapsible
        let elemscollapsible = document.querySelectorAll('.collapsible');
        window.M.Collapsible.init(elemscollapsible, options);

    }
    checkAll = (e) => {
            this.setState({
                checkAllFamilias: !this.state.checkAllFamilias
            });
    }

    createContainerTable = () => {
        //console.log("PRODUCTOS  ", this.state.productsCopy)
        let resultado = [];
        let group_familia = _.groupBy(this.state.productsCopy, "subfamilia");
        let count = 0;
        count_tr = 0;

        //console.log("PRODUCTOS CREACION TABLA: ", group_familia)
        _.forEach(group_familia, (element, key) => {
            count++;
            resultado.push(
                <li key={count}>
                    <div className="collapsible-header listasurtido">
                        <div className="row  ">

                            <b>{key}: tiene en surtido <span className="num-ref-surtido">{this.numeroReferencias(element)}</span> / {element.length} productos en Catálogo</b>

                        </div>

                    </div>
                    <div className="collapsible-body" id="bodycollapsiblesurtido">
                        {this.createTable(element)}
                    </div>
                </li>
            );
        });

        return resultado;
    }
    createTable = (products) => {
        let resultado = [];
        resultado.push(
            <div className="row margin-with" key={100}>
                <div className="col s6 m6 l6 xl6" id="containerprodspar">
                    <table className="table highlight" id="informe">
                        <thead className="theadinforme">
                            <tr className="trtheadsurtido">
                                <th className="border-table center">Nombre producto</th>
                                <th className="aligncenter border-table center">EAN</th>
                                <th className="aligncenter border-table center">Código cliente</th>
                                <th className="aligncenter border-table center">Acción</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.createTrTdTablePar(products)}
                        </tbody>
                    </table>
                </div>
                <div className="col s6 m6 l6 xl6" id="containerprodsimpar">
                    <table className="table highlight" id="informe">
                        <thead className="theadinforme">
                            <tr className="trtheadsurtido">
                                <th className="border-table center">Nombre producto</th>
                                <th className="aligncenter border-table center">EAN</th>
                                <th className="aligncenter border-table center">Código cliente</th>
                                <th className="aligncenter border-table center">Acción</th>
                            </tr>
                        </thead>
                        <tbody>
                            {this.createTrTdTableImpar(products)}
                        </tbody>
                    </table>
                </div>
            </div>
        );

        return resultado;
    }
    //-------------------------------------------------------------
    createTrTdTablePar = (products) => {
        let resultado = [];
        for (let i = 0; i < products.length; i++) {
            if (i % 2 === 0) {
                count_tr++;
                let clase_tr = "tr" + count_tr;
                let codigoCliente = "";
                let activeAlta = "";
                let addSurt = "";
                let activeBaja = " hide";
                _.each(this.state.surtidoCliente, (surti) => {
                    if (surti.idEnsenya === this.state.idEnsenyaSelected && surti.idProducto === products[i].idProducto) {
                        codigoCliente = surti.codigo_cliente
                        activeAlta = " hide";
                        activeBaja = "";
                        addSurt = " addsurtido"
                    }
                })
                resultado.push(
                    <tr className={`nohover ${clase_tr} ${addSurt} tablesurtido`} idprod={products[i].idProducto} key={products[i].idProducto}>
                        <td className="tdPrice sizefont12 td-style border-table center">{products[i].nombre}</td>
                        <td className="tdPrice aligncenter sizefont12 td-style border-table center">{products[i].EAN13}</td>
                        <td className="tdPrice aligncenter sizefont12 td-style border-table center">{codigoCliente}</td>
                        <td className="tdPrice aligncenter sizefont12 td-style border-table center">
                            <div className="dispflex justify-content-center">
                                <i className={`material-icons ${codigoCliente} ${activeAlta} tooltipped checkoutsurtido`} id="checkoutsurtido" codigo={codigoCliente} idprod={products[i].idProducto} fortr={clase_tr} data-tooltip="<a href='#' class='btn black-tooltip'>Dar de alta</a>" onClick={this.addToSurtido}>check_box_outline_blank</i>
                                <i className={`material-icons ${codigoCliente} ${activeBaja} tooltipped checkinsurtido`} id="checkinsurtido" codigo={codigoCliente} idprod={products[i].idProducto} fortr={clase_tr} data-tooltip="<a href='#' class='btn black-tooltip'>Dar de baja</a>" onClick={this.addToSurtido}>check_box</i>
                            </div>
                        </td>
                    </tr>
                );
            }
        }

        return resultado;
    }
    searcherFilter = () => {
        let allelem = [];
        let busqueda = document.getElementById('inputsearch').value

        if (this.state.productsFilter.length !== 0) {
            allelem = this.state.productsFilter;
        } else {
            allelem = this.state.products;
        }

        let filterData = allelem.filter(elem => {
            let item1 = elem.nombre && elem.nombre.toLowerCase();
            let item2 = elem.EAN13;
            if (item1 && item1.indexOf(busqueda.toLowerCase()) !== -1) {
                return true;
            }
            else if (item2 && item2.indexOf(busqueda) !== -1) {
                return true;
            } else {
                return false;
            }
        })

        this.setState({
            search: busqueda,
            productsCopy: filterData
        });
    }


    //-------------------------------------------------------------
    createTrTdTableImpar = (products) => {
        //console.log("PRODUCTOS: ", products)
        let resultado = [];
        for (let i = 0; i < products.length; i++) {
            if (i % 2 !== 0) {
                count_tr++;
                let clase_tr = "tr" + count_tr;
                let addSurt = ""
                let codigoCliente = "";
                let activeAlta = "";
                let activeBaja = "hide";
                _.each(this.state.surtidoCliente, (surti) => {
                    if (surti.idEnsenya === this.state.idEnsenyaSelected && surti.idProducto === products[i].idProducto) {
                        codigoCliente = surti.codigo_cliente
                        activeAlta = "hide";
                        activeBaja = "";
                        addSurt = " addsurtido"
                    }
                })
                resultado.push(
                    <tr className={`nohover ${clase_tr} ${addSurt} tablesurtido`} idprod={products[i].idProducto} key={products[i].idProducto}>
                        <td className="tdPrice sizefont12 td-style border-table center">{products[i].nombre}</td>
                        <td className="tdPrice aligncenter sizefont12 td-style border-table center">{products[i].EAN13}</td>
                        <td className="tdPrice aligncenter sizefont12 td-style border-table center">{codigoCliente}</td>
                        <td className="tdPrice aligncenter sizefont12 td-style border-table center">
                            <div className="dispflex justify-content-center">
                                <i className={`material-icons ${codigoCliente} ${activeAlta} <a href='#' class='btn black-tooltip'> checkoutsurtido`} id="checkoutsurtido" codigo={codigoCliente} idprod={products[i].idProducto} fortr={clase_tr} data-tooltip="<a href='#' class='btn black-tooltip'>Dar de alta</a>" onClick={this.addToSurtido}>check_box_outline_blank</i>
                                <i className={`material-icons ${codigoCliente} ${activeBaja} tooltipped checkinsurtido`} id="checkinsurtido" codigo={codigoCliente} idprod={products[i].idProducto} fortr={clase_tr} data-tooltip="<a href='#' class='btn black-tooltip'>Dar de baja</a>" onClick={this.addToSurtido}>check_box</i>
                            </div>
                        </td>
                    </tr>
                );
            }
        }

        return resultado;
    }

    render() {
        let showTable = false;
        //console.log("SHOW TABLE: ", showTable);
        if (this.state.idEnsenyaSelected !== '' && this.state.idEnsenyaSelected !== '-1') {
            showTable = true;
        }
        if (!this.state.sync) {
            return null;
        } else {

            return (
                <div className="row">
                    <div className="container  margin-top-2p">
                        <ButtonForm text="Filtros" event={this.openModalFilter}></ButtonForm>

                    </div>
                    <div className="row">
                        <div className="col l12 s12 margin-top-1p">

                            <div className="table-responsive">
                                {showTable ?
                                    <ul className="collapsible" id="collapsibleulsurtido">
                                        {this.createContainerTable()}
                                    </ul>
                                    : null}
                            </div>

                        </div>
                    </div>
                    {/************** MODALES ***************/}
                    <Modal isOpen={this.state.modalFilter}
                        onRequestClose={this.closeModalFilter}
                        onAfterOpen={this.afterOpenModal}
                        contentLabel="Filter Modal"
                        ariaHideApp={false}
                        style={customModalDelete}>
                        <div className="row">
                            <div className="row filtros-surtido" id="divbuscaadmin">
                                <br></br>
                                <div className="input-field col s3 m3 l3 xl3 selectfamiliasurtido">
                                    <select tipo="clientes" id="selectclientes" key={1000} onChange={this.selectCliente} defaultValue={this.state.idClienteSelected}>
                                        {this.createOptionSelect(this.state.clientes, "clientes")}
                                    </select>
                                    <label>Selecciona cliente</label>
                                </div>
                                <div className="input-field col s3 m3 l3 xl3 selectfamiliasurtido">
                                    <select tipo="cadenas" id="selectcadenas" key={1001} onChange={this.selectCadena} defaultValue={this.state.cadenaSelect}>
                                        {this.createOptionSelect(this.state.cadenas, "cadenas")}
                                    </select>
                                    <label>Selecciona cadena</label>
                                </div>
                                <div className="input-field col s3 m3 l3 xl3 selectfamiliasurtido">
                                    <select tipo="ensenyas" id="selectensenyas" key={1002} onChange={this.getSelectedEnsenya} defaultValue={this.state.idEnsenyaSelected} >
                                        {this.createOptionSelect(this.state.ensenyas, "ensenyas")}
                                    </select>
                                    <label>Selecciona enseña</label>
                                </div>
                                <div className="col s3 m3 l3 xl3 ">
                                    <div className="input-field col s12  selectfamiliasurtido">
                                        <select tipo="familias" id="selectfamilias" key={1003} multiple  >
                                            {this.createOptionSelect(this.state.familias, "familias")}
                                        </select>
                                        <label>Selecciona familia</label>
                                    </div>
                                    <br/>
                                    <div className="col s12 ">
                                        <label>
                                            <input type="checkbox" class="filled-in" onChange={this.checkAll} />
                                            <span>Todas</span>
                                        </label>

                                    </div>

                                </div>
                                {/* <div className="file-field input-field col s4 m4 l4 xl4">
                                <div className="btn" onClick={}>
                                    <i className="material-icons">search</i>
                                </div>
                                <div className="file-path-wrapper">
                                    <input id="inputsearch" className="file-path validate" type="text" placeholder="Buscar.." />
                                </div>
                            </div> */}
                            </div>
                            <div className="row"><span><i className="material-icons left blue-text">info</i>Para mostrar el catálogo del surtido seleccione el <b>Cliente</b>, la <b>Cadena</b>, la <b>Enseña</b> y la <b>Familia</b> del surtido</span></div>
                            <button href="#!" className=" btn waves-effect margin-5-5 color-red right" onClick={this.closeModalFilter} >Cerrar</button>
                            <button href="#!" className=" btn waves-effect margin-5-5 right" onClick={this.buscarSurtido} >Buscar</button>
                        </div>
                    </Modal>
                </div>
            );
        }

    }

}

export default Surtidos