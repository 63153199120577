import React, { Component } from 'react';
import _, { toUpper } from 'lodash';
const url_tablas = "https://www.perfect-store.es/serverside/tiendas.php";

class ListaTiendas extends Component {

    constructor(props) {
        super(props);
        this.state = {
            tiendas: [],
            tiendasCopy: [],
            canales: [],
            zonas: [],
            ensenyas: [],
            cadenaIdSelected: '',
            cadenas: [],
            provincias: [],
            selectTienda: null,
            filter: false
        };
    }

    componentDidMount() {
        if (document.getElementById("titlePage")) {
            document.getElementById("titlePage").innerHTML = toUpper("lista Tiendas");
        }
        let http_request = new XMLHttpRequest();
        http_request.open("POST", url_tablas, true);
        let obj = {
            tipo_peticion: "",
            user: null
        }
        http_request.send(JSON.stringify(obj));
        http_request.onreadystatechange = () => {
            if (http_request.readyState === XMLHttpRequest.DONE && http_request.status === 200) {
                let data = JSON.parse(http_request.responseText);
                let tiendaList = _.sortBy(data.tiendas, o => -o.idEst, ['asc']);
                console.log("DATA: ", data);
                this.setState({
                    tiendas: tiendaList,
                    tiendasCopy: tiendaList,
                    canales: data.canales,
                    zonas: data.zonas,
                    ensenyas: data.ensenyas,
                    cadenas: data.cadenas,
                    provincias: data.provincias,
                }, () => {
                    // console.log("state cambiado", this.state);
                    // window.M.AutoInit();
                });
            }
        }
    }
    componentDidUpdate() {
        window.M.AutoInit();
        const options4 = { dismissible: false };
        let elem = document.getElementById("borrartienda");
        window.M.Modal.init(elem, options4);
        let elem1 = document.getElementById("nuevotienda");
        window.M.Modal.init(elem1, options4);
        let elem2 = document.getElementById("editartienda");
        window.M.Modal.init(elem2, options4);
    }
    activeModalAdd = (e) => {
        document.getElementById("codigo").value = '';
        document.getElementById("codigo_cliente").value = '';
        document.getElementById("nombre").value = '';
        document.getElementById("canal").value = -1;
        document.getElementById("cadena").value = -1;
        document.getElementById("ensenya").value = -1;
        document.getElementById("provincia").value = -1;
        document.getElementById("poblacion").value = '';
        document.getElementById("direccion").value = '';
        document.getElementById("cp").value = '';
        document.getElementById("zona").value = -1;
        let elem = document.getElementById("nuevotienda");
        let instance = window.M.Modal.getInstance(elem);
        instance.open();
    }
    activeModalUpdate = (e) => {
        let idtienda = e.currentTarget.getAttribute('id');
        this.state.tiendas.forEach(tienda => {
            if (tienda.idEst === idtienda) {
                document.getElementById("edit_codigo").value = tienda.codigo;
                document.getElementById("edit_codigo_cliente").value = tienda.codigo_cliente;
                document.getElementById("edit_canal").value = tienda.canal;
                window.M.FormSelect.init(document.getElementById("edit_canal"));
                document.getElementById("edit_nombre").value = tienda.nombre;
                document.getElementById("edit_cadena").value = tienda.idCadena;
                window.M.FormSelect.init(document.getElementById("edit_cadena"));
                document.getElementById("edit_ensenya").value = tienda.idEnsenya;
                window.M.FormSelect.init(document.getElementById("edit_ensenya"));
                document.getElementById("edit_provincia").value = tienda.idProvincia;
                window.M.FormSelect.init(document.getElementById("edit_provincia"));
                document.getElementById("edit_poblacion").value = tienda.poblacion;
                document.getElementById("edit_direccion").value = tienda.direccion;
                document.getElementById("edit_cp").value = tienda.cp;
                document.getElementById("edit_zona").value = tienda.zona;
                window.M.FormSelect.init(document.getElementById("edit_zona"));
                // console.log("Tienda Seleccionada: ", tienda);
            }
        });

        this.setState({
            selectTienda: idtienda
        }, () => {
            // console.log("State Borrar", this.state);
            let elem = document.getElementById("editartienda");
            let instance = window.M.Modal.getInstance(elem);
            instance.open();
            window.M.updateTextFields();

        });

    }
    activeModalDelete = (e) => {
        let idtienda = e.currentTarget.getAttribute('id')
        // console.log("idtienda: ", idtienda);
        this.setState({
            selectTienda: idtienda
        }, () => {
            // console.log("State Borrar", this.state);
            let elem = document.getElementById("borrartienda");
            let instance = window.M.Modal.getInstance(elem);
            instance.open();
        });
    }
    altaTienda = () => {
        let list = this.state.tiendas;
        let codigo = document.getElementById("codigo").value;
        let codigo_cliente = document.getElementById("codigo_cliente").value;
        let nombre = document.getElementById("nombre").value;
        let canal = document.getElementById("canal").value;
        let idCadena = document.getElementById("cadena").value;
        let nombre_cadena = document.getElementById("cadena").options[document.getElementById("cadena").selectedIndex].text;
        let idEnsenya = document.getElementById("ensenya").value;
        let nombre_ensenya = document.getElementById("ensenya").options[document.getElementById("ensenya").selectedIndex].text;
        let idProvincia = document.getElementById("provincia").value;
        let nombre_provincia = document.getElementById("provincia").options[document.getElementById("provincia").selectedIndex].text;
        let poblacion = document.getElementById("poblacion").value;
        let direccion = document.getElementById("direccion").value;
        let cp = document.getElementById("cp").value;
        let zona = document.getElementById("zona").value;


        if (nombre && canal && idCadena && idEnsenya && idProvincia && poblacion && direccion && cp && zona) {
            let http_request = new XMLHttpRequest();
            http_request.open("POST", url_tablas, true);
            let newtienda = {
                idEst: '',
                codigo: codigo,
                codigo_cliente: codigo_cliente,
                nombre: nombre,
                canal: canal,
                idCadena: idCadena,
                cadena_nombre: nombre_cadena,
                idEnsenya: idEnsenya,
                ensenya_nombre: nombre_ensenya,
                idProvincia: idProvincia,
                provincia_nombre: nombre_provincia,
                poblacion: poblacion,
                direccion: direccion,
                cp: cp,
                zona: zona,
            }
            let obj = {
                tipo_peticion: "insert",
                user: newtienda
            }
            // console.log("OBJETO TIENDA: ", newtienda);
            http_request.send(JSON.stringify(obj));
            http_request.onreadystatechange = () => {
                if (http_request.readyState === XMLHttpRequest.DONE && http_request.status === 200) {
                    let data = JSON.parse(http_request.responseText);
                    // console.log("DATA: ", data);
                    newtienda.idEst = data.idEst;
                    this.setState({
                        tiendas: list
                    }, () => {
                        list.push(newtienda);
                        // console.log("state cambiado", this.state);
                        window.M.toast({ html: 'Faltan campos por rellenar' })
                        // window.M.AutoInit();
                    });
                }
            }
        } else {
            window.M.toast({ html: 'Faltan campos por rellenar' })
        }
    }
    deleteTienda = () => {
        let lista = this.state.tiendas;
        let idtienda = this.state.selectTienda;
        let http_request = new XMLHttpRequest();
        http_request.open("POST", url_tablas, true);
        let obj = {
            tipo_peticion: "delete",
            user: {
                idEst: idtienda
            }
        }
        http_request.send(JSON.stringify(obj));
        http_request.onreadystatechange = () => {
            if (http_request.readyState === XMLHttpRequest.DONE && http_request.status === 200) {

                let dataTiendas = _.remove(lista, function (tienda) {
                    // console.log("SON S : ", parseInt(tienda.idEst) + " | " + idtienda)
                    // console.log("SON IGUALES : ", parseInt(tienda.idEst) !== idtienda)
                    return parseInt(tienda.idEst) !== parseInt(idtienda);
                });

                this.setState({
                    tiendas: dataTiendas
                }, () => {
                    window.M.toast({ html: 'La tienda ha sido borrada' })
                    // console.log("state cambiado", this.state);
                    // window.M.AutoInit();
                });
            }
        }
    }
    updateTienda = () => {
        let list = this.state.tiendas;
        let idtienda = this.state.selectTienda;
        let codigo = document.getElementById("edit_codigo").value;
        let codigo_cliente = document.getElementById("edit_codigo_cliente").value;
        let nombre = document.getElementById("edit_nombre").value;
        let canal = document.getElementById("edit_canal").value;
        let idCadena = document.getElementById("edit_cadena").value;
        let nombre_cadena = document.getElementById("edit_cadena").options[document.getElementById("edit_cadena").selectedIndex].text;
        let idEnsenya = document.getElementById("edit_ensenya").value;
        let nombre_ensenya = document.getElementById("edit_ensenya").options[document.getElementById("edit_ensenya").selectedIndex].text;
        let idProvincia = document.getElementById("edit_provincia").value;
        let nombre_provincia = document.getElementById("edit_provincia").options[document.getElementById("edit_provincia").selectedIndex].text;
        let poblacion = document.getElementById("edit_poblacion").value;
        let direccion = document.getElementById("edit_direccion").value;
        let cp = document.getElementById("edit_cp").value;
        let zona = document.getElementById("edit_zona").value;
        if (idtienda && canal && nombre && idCadena && idEnsenya && idProvincia && poblacion && direccion && cp && zona) {
            let http_request = new XMLHttpRequest();
            http_request.open("POST", url_tablas, true);
            let newtienda = {
                idEst: idtienda,
                codigo: codigo,
                codigo_cliente: codigo_cliente,
                nombre: nombre,
                canal: canal,
                idCadena: idCadena,
                cadena_nombre: nombre_cadena,
                idEnsenya: idEnsenya,
                ensenya_nombre: nombre_ensenya,
                idProvincia: idProvincia,
                provincia_nombre: nombre_provincia,
                poblacion: poblacion,
                direccion: direccion,
                cp: cp,
                zona: zona
            }

            let obj = {
                tipo_peticion: "update",
                user: newtienda
            }
            http_request.send(JSON.stringify(obj));
            http_request.onreadystatechange = () => {
                if (http_request.readyState === XMLHttpRequest.DONE && http_request.status === 200) {
                    // console.log("DATA: ", data);
                    // console.log("id: ", idtienda);

                    let newListTiendas = _.remove(list, function (tienda) {
                        return tienda.idEst !== idtienda;
                    })

                    newListTiendas.push(newtienda);
                    this.setState({
                        tiendas: newListTiendas
                    }, () => {
                        // console.log("state cambiado", this.state);
                        window.M.toast({ html: 'El tienda ha sido actualizada' })
                        // window.M.AutoInit();
                    });
                }
            }
        } else {
            window.M.toast({ html: 'Faltan campos por rellenar' })
        }
    }
    clearProductos = () => {
        document.getElementById("filter_cadena").value = '-1';
        document.getElementById("filter_ensenya").value = '-1';
        document.getElementById("filter_canal").value = '-1';
        window.M.AutoInit()
        let newListTiendas = this.state.tiendasCopy;
        this.setState({
            tiendas: newListTiendas,
            filter: false
        });
    }
    buscarProductos = () => {
        let cadena = this.state.cadenaIdSelected;
        let ensenya = document.getElementById("filter_ensenya").value;
        let canal = document.getElementById("filter_canal").value;
        let newListTiendas = _.filter(this.state.tiendasCopy, ['idCadena', cadena]);

        if (ensenya !== '-1') {
            newListTiendas = _.filter(newListTiendas, ['idEnsenya', ensenya]);
        }
        if (canal !== '-1' && canal !== '0') {
             newListTiendas = _.filter(newListTiendas, ['canal', canal]);
        }
        this.setState({
            tiendas: newListTiendas,
            filter: true
        });

    }
    changeCadena = (e) => {
        let idCadena = e.target.value;
        this.setState({
            cadenaIdSelected: idCadena
        }, () => {
            window.M.AutoInit()
        });
    }
    chargeselectFliter = (tipo) => {
        let html = []
        switch (tipo) {

            case 'cadenas':
                let list2 = this.state.cadenas;
                list2.forEach((cadena) => {
                    html.push(<option key={"filter_" + cadena.idCadena} value={cadena.idCadena}>{cadena.nombre}</option>);
                });
                break;
            case 'ensenyas':
                let list3 = this.state.ensenyas;
                let id = this.state.cadenaIdSelected;
                console.log("ID CADENA FILTER: ", id)
                list3.forEach((ensenya) => {
                    console.log(" id FOR: ", ensenya.idCadena)
                    console.log(" FILTER: ", id)
                    if (ensenya.idCadena === id) {
                        html.push(<option key={"filter_" + ensenya.idEnsenya} value={ensenya.idEnsenya}>{ensenya.nombre}</option>);
                    }
                });
                break;
            case 'canales':
                let list1 = this.state.canales;
                html.push(<option key={"filter_"+"99999"} value="0">Todos</option>);
                list1.forEach((canal, index) => {
                    html.push(<option key={index} value={canal.canal}>{canal.canal}</option>);
                });
                break;
            default:
                break;
        }
        return html;
    }
    chargeselect = (tipo) => {
        let html = []
        switch (tipo) {
            case 'canales':
                let list1 = this.state.canales;
                list1.forEach((canal, index) => {
                    html.push(<option key={index} value={canal.canal}>{canal.canal}</option>);
                });
                break;
            case 'cadenas':

                let list2 = this.state.cadenas;
                list2.forEach((cadena) => {
                    html.push(<option key={cadena.idCadena} value={cadena.idCadena}>{cadena.nombre}</option>);
                });
                break;
            case 'ensenyas':
                let list3 = this.state.ensenyas;
                list3.forEach((ensenya) => {
                    html.push(<option key={ensenya.idEnsenya} value={ensenya.idEnsenya}>{ensenya.nombre}</option>);
                });
                break;
            case 'zonas':
                let list4 = this.state.zonas;
                list4.forEach((zona, index) => {
                    html.push(<option key={index} value={zona.zona}>{zona.zona}</option>);
                });
                break;
            case 'provincias':
                let list5 = this.state.provincias;
                list5.forEach((provincia) => {
                    html.push(<option key={provincia.idProvincia} value={provincia.idProvincia}>{provincia.provincia}</option>);
                });
                break;
            default:
                break;
        }
        return html;
    }
    chargeListTiendas = () => {
        let html = []
        let list = this.state.tiendas

        if (list.length > 0) {
            list.forEach((tienda, key) => {
                if(key < 80 || this.state.filter){
                     html.push(<tr key={tienda.idEst}>
                    <td className="center padding5-5 border-table size-3"> {tienda.idEst} </td>
                    <td className="center padding5-5 border-table size-3"> {tienda.codigo ? tienda.codigo : "-"} </td>
                    <td className="center padding5-5 border-table size-3"> {tienda.codigo_cliente ? tienda.codigo_cliente : "-"} </td>
                    <td className="center padding5-5 border-table size-3"> {tienda.nombre} </td>
                    <td className="center padding5-5 border-table size-3"> {tienda.canal} </td>
                    <td className="center padding5-5 border-table size-3"> {tienda.cadena_nombre} </td>
                    <td className="center padding5-5 border-table size-3"> {tienda.ensenya_nombre} </td>
                    <td className="center padding5-5 border-table size-3"> {tienda.provincia_nombre} </td>
                    <td className="center padding5-5 border-table size-3"> {tienda.poblacion} </td>
                    <td className="center padding5-5 border-table size-3"> {tienda.direccion} </td>
                    <td className="center padding5-5 border-table size-3"> {tienda.cp} </td>
                    <td className="center padding5-5 border-table size-3"> {tienda.zona} </td>
                    <td className="center padding5-5 border-table width-5">
                        <div className=" btn-floating tooltipped margin-btn color-red margin-bot5" data-position="left" data-tooltip="<a href='#' class='btn black-tooltip'>Borrar</a>" id={tienda.idEst} onClick={this.activeModalDelete}><i className="material-icons size-1 white-text">delete</i></div>
                        <div className=" btn-floating margin-btn tooltipped color-blue margin-bot5" data-position="left" data-tooltip="<a href='#' class='btn black-tooltip'>Editar Tiendas</a>" id={tienda.idEst} onClick={this.activeModalUpdate}><i className="material-icons size-1 white-text">edit</i></div>
                    </td>
                </tr>);
                }               
            });
        } else {
            html.push(<tr key="0">
                <td className="center" colSpan="13" >No hay tiendas</td>
            </tr>);
        }

        return html;
    }

    render() {
        return (
            <div className="App">
                <div className="row ">
                    <div className="container margin-top-2p">

                    </div>
                    <div className="variable-cont">
                        <div className="file-field input-field col s2 left">
                            {/* {this.state.busquedaActiva ? 'btn color-red' : 'btn color-red hide'} */}
                            <div className='btn color-red' onClick={this.clearProductos}>
                                <i className="material-icons">rotate_left</i>
                            </div>
                            <div className="btn" onClick={this.buscarProductos}>
                                <i className="material-icons">search</i>
                            </div>
                            {/* <div className="file-path-wrapper">
                                <input id="buscar" className="file-path validate" type="text" placeholder="Buscar Producto.." />
                            </div> */}
                        </div>
                        <div className="input-field col s2">
                            <select id="filter_cadena" onChange={this.changeCadena} defaultValue="-1">
                                <option value='-1' disabled >Selecciona la Cadena</option>
                                {this.chargeselectFliter("cadenas")}
                            </select>
                        </div>
                        <div className="input-field col s2">
                            <select id="filter_ensenya" defaultValue="-1">
                                <option value='-1' disabled >Selecciona la Ensenya</option>
                                {this.chargeselectFliter('ensenyas')}
                            </select>
                        </div>
                        <div className="input-field col s2">
                            <select id="filter_canal" defaultValue="-1">
                                <option value='-1' disabled >Selecciona el Canal</option>
                                {this.chargeselectFliter('canales')}
                            </select>
                        </div>
                        <div className="waves-effect waves-light btn right margin-bot3p" onClick={this.activeModalAdd}><i className="material-icons right">add</i>Nuevo Tiendas</div>

                        <table className="tabla-kpis striped ">
                            <thead key="thead" className="">
                                <tr>
                                    <th className="center padding5-5">Id</th>
                                    <th className="center padding5-5">Cod.</th>
                                    <th className="center padding5-5">Cod.Cliente </th>
                                    <th className="center padding5-5">Nombre</th>
                                    <th className="center padding5-5">Canal</th>
                                    <th className="center padding5-5">Cadena</th>
                                    <th className="center padding5-5">Enseña</th>
                                    <th className="center padding5-5">Provincia</th>
                                    <th className="center padding5-5">Poblacion</th>
                                    <th className="center padding5-5">Direccion</th>
                                    <th className="center padding5-5">C.P.</th>
                                    <th className="center padding5-5">Zona</th>
                                    <th className="center padding5-5">Accion</th>
                                </tr>
                            </thead>
                            <tbody key="tbody">
                                {this.chargeListTiendas()}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div id="borrartienda" className="modal color-yellow">
                    <div className="modal-content">
                        <h4>Borrar Tienda</h4>
                        <p>Estas seguro que quieres borrar esta Tienda?</p>
                    </div>
                    <div className="modal-footer color-yellow">
                        <a href="#" className="modal-close waves-effect waves-green btn-flat color-green white-text" onClick={this.deleteTienda}>Sí, Seguro</a>
                        &nbsp; &nbsp;
                        <a href="#" className="modal-close waves-effect waves-green btn-flat color-red white-text">Cancelar</a>
                    </div>
                </div>
                <div id="nuevotienda" className="modal color-yellow border-rad">
                    <div className="modal-content">
                        <h4>Añadir Tienda</h4>
                        <form className="col s12">
                            <div className="row">
                                <div className="input-field col s6">
                                    <input id="codigo" type="text" className="validate" />
                                    <label htmlFor="codigo">Codigo</label>
                                </div>
                                <div className="input-field col s6">
                                    <input id="codigo_cliente" type="text" className="validate" />
                                    <label htmlFor="codigo_cliente">Codigo Cliente</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-field col s5">
                                    <input id="nombre" type="text" className="validate" />
                                    <label htmlFor="nombre">Nombre</label>
                                </div>
                                <div className="input-field col s7">
                                    <input id="direccion" type="text" className="validate" />
                                    <label htmlFor="direccion">Dirección</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-field col s6">
                                    <select id="cadena">
                                        <option defaultValue="-1" disabled selected >Selecciona la Cadena</option>
                                        {this.chargeselect("cadenas")}
                                    </select>
                                </div>
                                <div className="input-field col s6">
                                    <select id="ensenya">
                                        <option defaultValue="-1" disabled selected >Selecciona la Enseña</option>
                                        {this.chargeselect("ensenyas")}
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-field col s4">
                                    <select id="canal">
                                        <option defaultValue="-1" disabled selected >Selecciona el Canal</option>
                                        {this.chargeselect("canales")}
                                    </select>
                                </div>
                                <div className="input-field col s4">
                                    <select id="zona">
                                        <option defaultValue="-1" disabled selected >Selecciona la Zona</option>
                                        {this.chargeselect("zonas")}
                                    </select>
                                </div>
                                <div className="input-field col s4">
                                    <select id="provincia">
                                        <option defaultValue="-1" disabled selected>Selecciona la Provincia</option>
                                        {this.chargeselect("provincias")}
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-field col s6">
                                    <input id="poblacion" type="text" className="validate" />
                                    <label htmlFor="poblacion">Población</label>
                                </div>
                                <div className="input-field col s6">
                                    <input id="cp" type="text" className="validate" />
                                    <label htmlFor="cp">Codigo Postal</label>
                                </div>
                            </div>
                        </form>
                        <div className=" col s12">
                            <a href="#" className="modal-close waves-effect waves-green btn-modal btn-flat color-green white-text right" onClick={this.altaTienda}>Dar de Alta</a>
                            <a href="#" className="modal-close waves-effect waves-green btn-modal btn-flat color-red white-text right">Cancelar</a>
                        </div>
                    </div>
                </div>
                <div id="editartienda" className="modal color-yellow">
                    <div className="modal-content">
                        <h4>Actualizar Tiendas</h4>
                        <form className="col s12">
                            <div className="row">
                                <div className="input-field col s6">
                                    <input id="edit_codigo" type="text" className="validate" />
                                    <label htmlFor="edit_codigo">Codigo</label>
                                </div>
                                <div className="input-field col s6">
                                    <input id="edit_codigo_cliente" type="text" className="validate" />
                                    <label htmlFor="edit_codigo_cliente">Codigo Cliente</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-field col s5">
                                    <input id="edit_nombre" type="text" className="validate" />
                                    <label htmlFor="edit_nombre">Nombre</label>
                                </div>
                                <div className="input-field col s7">
                                    <input id="edit_direccion" type="text" className="validate" />
                                    <label htmlFor="edit_direccion">Dirección</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-field col s6">
                                    <select id="edit_cadena">
                                        <option defaultValue="-1" disabled selected>Selecciona la Cadena</option>
                                        {this.chargeselect("cadenas")}
                                    </select>
                                </div>
                                <div className="input-field col s6">
                                    <select id="edit_ensenya">
                                        <option defaultValue="-1" disabled selected>Selecciona la Enseña</option>
                                        {this.chargeselect("ensenyas")}
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-field col s4">
                                    <select id="edit_canal">
                                        <option defaultValue="-1" disabled selected>Selecciona el Canal</option>
                                        {this.chargeselect("canales")}
                                    </select>
                                </div>
                                <div className="input-field col s4">
                                    <select id="edit_zona">
                                        <option defaultValue="-1" disabled selected>Selecciona la Zona</option>
                                        {this.chargeselect("zonas")}
                                    </select>
                                </div>
                                <div className="input-field col s4">
                                    <select id="edit_provincia">
                                        <option defaultValue="-1" disabled selected>Selecciona la Provincia</option>
                                        {this.chargeselect("provincias")}
                                    </select>
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-field col s6">
                                    <input id="edit_poblacion" type="text" className="validate" />
                                    <label htmlFor="edit_poblacion">Población</label>
                                </div>
                                <div className="input-field col s6">
                                    <input id="edit_cp" type="text" className="validate" />
                                    <label htmlFor="edit_cp">Codigo Postal</label>
                                </div>
                            </div>
                        </form>
                        <div className=" col s12">
                            <a href="#" className="modal-close waves-effect waves-green btn-modal btn-flat color-green white-text right" onClick={this.updateTienda}>Actualizar</a>
                            <a href="#" className="modal-close waves-effect waves-green btn-modal btn-flat color-red white-text right">Cancelar</a>
                        </div>

                    </div>
                </div>
            </div>
        );
    }
}

export default ListaTiendas