import React, { Component } from 'react';
import ButtonKPI from './Elementos/ButtonKPI';
import ButtonForm from './Elementos/ButtonForm';
import _, { toUpper } from 'lodash';

const url_tablas = "https://www.perfect-store.es/serverside/clientes.php";

class AltaClientEdit extends Component {

    constructor(props) {
        super(props);
        // No llames this.setState() aquí!
        this.state = {
            nombreCliente: this.props.history.location.state.nombreCliente,
            idCliente: this.props.history.location.state.idCliente,
            buttonsKpis: [


                { num: 1, titulo: "Presencia Surtido", icon: "present_to_all", colorBack: "color-presencia" },
                { num: 2, titulo: "Presencia Prod. Claves", icon: "present_to_all ", colorBack: "color-presencia" },
                { num: 3, titulo: "Presencia Prod. Innovación", icon: "present_to_allt", colorBack: "color-presencia" },
                { num: 4, titulo: "OOS Surtido", icon: "bug_report", colorBack: "color-presencia" },
                { num: 5, titulo: "OOS Prod. Clave", icon: "bug_report", colorBack: "color-presencia" },
                { num: 6, titulo: "OOS Prod. Innovación", icon: "bug_report", colorBack: "color-presencia" },
                { num: 7, titulo: "P. Recomendado Surtido", icon: "euro_symbol", colorBack: "color-presencia" },
                { num: 8, titulo: "P. Recomendado Clave", icon: "euro_symbol", colorBack: "color-presencia" },
                { num: 9, titulo: "P. Recomendado Prod. Innovación", icon: "euro_symbol", colorBack: "color-presencia" },

                { num: 10, titulo: "Altura Surtido", icon: "import_export", colorBack: "color-activacion" },
                { num: 11, titulo: "Altura Prod. Clave", icon: "import_export", colorBack: "color-activacion" },
                { num: 12, titulo: "Altura Prod. Innovación", icon: "import_export", colorBack: "color-activacion" },
                { num: 13, titulo: "Bloque de marca", icon: "view_comfy", colorBack: "color-activacion" },
                { num: 14, titulo: "P. Señalizado Surtido", icon: "euro_symbol", colorBack: "color-activacion" },
                { num: 15, titulo: "P. Señalizado Prod. Claves", icon: "euro_symbol", colorBack: "color-activacion" },
                { num: 16, titulo: "P. Señalizado Prod. Innovación", icon: "euro_symbol", colorBack: "color-activacion" },
                { num: 17, titulo: "POP Colocado en el Lineal", icon: "flag", colorBack: "color-activacion" },
                { num: 18, titulo: "Cuota de Lineal", icon: "linear_scale", colorBack: "color-activacion" },

                { num: 19, titulo: "Promociones en Lineal", icon: "new_releases", colorBack: "color-visibilidad" },
                { num: 20, titulo: "Segunda Exposiciones", icon: "filter_2", colorBack: "color-visibilidad" },
            ],
            kpis: [],
            controlTotal: 0
        };
    }
    componentDidMount() {
        if (document.getElementById("titlePage")) {
            document.getElementById("titlePage").innerHTML = toUpper("modifica cliente");
        }
        let http_request = new XMLHttpRequest();
        http_request.open("POST", url_tablas, true);
        let obj = {
            tipo_peticion: "select-edit",
            user: {
                idCliente: this.state.idCliente,
            }
        }
        let listkpi = [];
        http_request.send(JSON.stringify(obj));
        http_request.onreadystatechange = () => {
            if (http_request.readyState === XMLHttpRequest.DONE && http_request.status === 200) {
                let data = JSON.parse(http_request.responseText);
                // console.log("DATA: ", data);
                document.getElementById("first_name").value = this.state.nombreCliente;
                for (let o = 1; o <= 20; o++) {
                    if (data[0]["kpi" + o] !== "0") {
                        listkpi.push({
                            id: o,
                            valor: parseInt(data[0]["max_valor_kpi" + o]),
                            title: "Kpi " + o
                        });
                        if (data[0]["max_valor_kpi" + o] > 0) {
                            document.getElementById("number_kpi_" + o).value = data[0]["max_valor_kpi" + o];
                        }
                    }
                }
            }
            this.setState({
                kpis: listkpi,
                controlTotal: parseInt(100),
            }, () => {
                // console.log("state cambiado", this.state);

            });
        }
    }

    clickOnInput = (e) => {
        // window.M.toast({ html: e.target.getAttribute("titulo") })
        let kpisList = this.state.kpis;
        if (document.getElementById("number_kpi_" + e.target.getAttribute("num_kpi")).value === '') {
            document.getElementById("number_kpi_" + e.target.getAttribute("num_kpi")).value = 0;
        }
        let valorkpi = parseInt(document.getElementById("number_kpi_" + e.target.getAttribute("num_kpi")).value);

        let num_kpi = e.target.getAttribute("num_kpi");
        let porcentaje = 0;
        let plusValor = false;
        // console.log("KPI List: ", kpisList);



        // console.log("ARRAY KPI LIST", kpisList);
        if (valorkpi || valorkpi === 0 || valorkpi === '') {
            if (valorkpi > 0) {
                if (kpisList.length > 0) {
                    kpisList.forEach((kpi, index) => {
                        if (parseInt(kpi.id) === parseInt(e.target.getAttribute("num_kpi"))) {
                            // console.log("PORCENTAJE ANTES DE RESTA: ", porcentaje);
                            // console.log("RESTA: ", restaKpi);
                            plusValor = true;
                            // console.log("PORCENTAJE DESPUES DE RESTA: ", porcentaje);
                            // console.log("SUMA: ", valorkpi);
                            porcentaje = porcentaje + valorkpi;
                        } else {
                            porcentaje = porcentaje + kpi.valor;
                            // console.log("PORCENTAJE 2: ", porcentaje);

                        }
                    })
                    if (!plusValor) {
                        porcentaje = porcentaje + valorkpi;
                    }
                } else {
                    porcentaje = porcentaje + valorkpi;
                    // console.log("PORCENTAJE 3: ", porcentaje);
                    window.M.toast({ html: porcentaje })
                }
                // console.log("PORCENTAJE FINAL: ", porcentaje);
                if (porcentaje <= 100) {
                    let find = true;
                    if (kpisList.length > 0) {
                        kpisList.forEach((kpi, index) => {
                            // console.log("Busca KPI: ", kpi.id + " || " + e.target.getAttribute("num_kpi"));
                            // console.log("resultado find: ", parseInt(kpi.id) == e.target.getAttribute("num_kpi"));
                            if (parseInt(kpi.id) === parseInt(e.target.getAttribute("num_kpi"))) {
                                kpisList[index].valor = valorkpi;
                                find = false;
                            }
                        });
                    }
                    if (find) {
                        // console.log("ENTRA EN CREAR KPI");
                        let kpiObject = {
                            id: parseInt(e.target.getAttribute("num_kpi")),
                            valor: parseInt(document.getElementById("number_kpi_" + e.target.getAttribute("num_kpi")).value),
                            title: e.target.getAttribute("titulo")
                        }
                        kpisList.push(kpiObject);
                    }
                    porcentaje = 0;
                    kpisList.forEach((kpi, index) => {
                        porcentaje = porcentaje + kpi.valor;
                    });
                    // console.log("kpisLtist: ", kpisList);
                    // console.log("porcentaje: ", porcentaje);
                    this.setState({
                        kpis: kpisList,
                        controlTotal: porcentaje
                    });
                } else {
                    window.M.toast({ html: "la suma total sobrepasa el 100%" })
                    document.getElementById("number_kpi_" + e.target.getAttribute("num_kpi")).value = '';
                    kpisList = _.remove(kpisList, function (n) {
                        return n.id !== parseInt(e.target.getAttribute("num_kpi"));
                    });

                    porcentaje = 0;
                    kpisList.forEach((kpi, index) => {
                        porcentaje = porcentaje + kpi.valor;
                    });
                    // console.log("kpisLtist: ", kpisList);
                    // console.log("porcentaje: ", porcentaje);
                    this.setState({
                        kpis: kpisList,
                        controlTotal: porcentaje
                    });
                }
            } else {
                kpisList = _.remove(kpisList, function (n) {
                    return n.id !== parseInt(e.target.getAttribute("num_kpi"));
                });

                let kpiObject = {
                    id: parseInt(e.target.getAttribute("num_kpi")),
                    valor: parseInt(document.getElementById("number_kpi_" + e.target.getAttribute("num_kpi")).value),
                    title: e.target.getAttribute("titulo")
                }
                kpisList.push(kpiObject);

                porcentaje = 0;

                kpisList.forEach((kpi, index) => {
                    porcentaje = porcentaje + kpi.valor;
                });
                // console.log("kpisLtist: ", kpisList);
                // console.log("porcentaje: ", porcentaje);
                this.setState({
                    kpis: kpisList,
                    controlTotal: porcentaje
                });
            }

        } else {
            let kpisListTemp = _.filter(kpisList, function (n) {
                return n.id !== parseInt(e.target.getAttribute("num_kpi"));
            });

            porcentaje = this.state.controlTotal - kpisListTemp[0].valor;

            kpisList = _.remove(kpisList, function (n) {
                return n.id !== parseInt(e.target.getAttribute("num_kpi"));
            });

            this.setState({
                kpis: kpisList,
                controlTotal: porcentaje
            });
        }
    }

    //Posible mejora de dinamismo

    kpiLoads = () => {
        let html = []
        this.state.buttonsKpis.forEach((kpi) => {
            html.push(<ButtonKPI key={kpi.num} id={kpi.num + "_kpi"} num_kpi={kpi.num} titulo={kpi.titulo} colorBack={kpi.colorBack} event={this.clickOnInput} nameIcon={kpi.icon} ></ButtonKPI>)
        })
        return html;
    }

    chargeTableHeadKpi = () => {
        let html = []
        let listkpi = this.state.kpis
        listkpi = _.orderBy(listkpi, ['id'], ['asc']);
        listkpi.forEach((kpi) => {
            if (kpi.valor !== 0) {
                html.push(<th>KPI-{kpi.id}</th>);
            }
        });
        if (listkpi.length > 0) {
            html.push(<th className="center"> % Total </th>);
        }

        return html;
    }

    chargeTableValorKpi = () => {
        let html = []
        let listkpi = this.state.kpis
        listkpi = _.orderBy(listkpi, ['id'], ['asc']);
        listkpi.forEach((kpi) => {
            if (kpi.valor !== 0) {
                html.push(<td> {kpi.valor} </td>);
            }
        });
        if (listkpi.length > 0) {
            if (this.state.controlTotal === 100) {
                html.push(<td className=" widthKpiTotal center padding5-5"> <span className="fill-complet-kpis">{this.state.controlTotal} % </span></td>);
            } else {
                html.push(<td className="center padding5-5"> {this.state.controlTotal} % </td>);
            }
        }
        return html;
    }

    guardarDatos = (e) => {
        // console.log("lista kpis: ", this.state.kpis);
        if (this.state.controlTotal === 100) {
            let http_request = new XMLHttpRequest();
            http_request.open("POST", url_tablas, true);
            console.log("Lista KPIs: ", this.state.kpis);
            let obj = {
                tipo_peticion: "edit",
                user: {
                    idCliente: this.state.idCliente,
                    nombre: document.getElementById("first_name").value,
                    kpis: this.state.kpis
                }
            }
            http_request.send(JSON.stringify(obj));
            http_request.onreadystatechange = () => {
                if (http_request.readyState === XMLHttpRequest.DONE && http_request.status === 200) {
                    window.M.toast({ html: 'Se han guardado los datos' })
                    this.props.history.goBack();
                }
            }

        }

    }
    render() {
        return (
            <div className="App">
                <div className="row">

                    <form className="col s10 offset-s1 margin-top-2p">
                        <div className="row">
                            <div className="input-field col s3">
                                <input id="first_name" type="text" className="validate" />
                                <label className="active" htmlFor="first_name"></label>
                            </div>
                            <div className="col s9">
                                <p className="leyenda col s2 offset-s3 color-presencia">PRESENCIA</p>
                                <p className="leyenda col s2 color-activacion">ACTIVACIÓN</p>
                                <p className="leyenda col s2 color-visibilidad">VISIBILIDAD</p>
                                <ButtonForm text="Guardar" event={this.guardarDatos}></ButtonForm>
                            </div>

                        </div>
                        <table className="tabla-kpis">
                            <thead>
                                <tr>
                                    {this.chargeTableHeadKpi()}
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    {this.chargeTableValorKpi()}
                                </tr>
                            </tbody>
                        </table>


                        <div className="row grid-kpi">
                            {this.kpiLoads()}

                        </div>

                    </form>
                    <div className="row">
                        <div className="col s10 offset-s1 btn-modal">
                            <div className="padding-10">
                                <ButtonForm text="Guardar" event={this.guardarDatos}></ButtonForm>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        );
    }

}

export default AltaClientEdit