import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import './App.css';
import * as serviceWorker from './serviceWorker';
import 'materialize-css/dist/css/materialize.min.css';
import 'material-design-icons/iconfont/material-icons.css'
import M from 'materialize-css';
// Browser Routes
import { BrowserRouter } from 'react-router-dom';

ReactDOM.render(<BrowserRouter basename={'.'} ><App/></BrowserRouter>, document.getElementById('root'));

// If you want your app to work  offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA si la carpeta principal es diferente basename={'.'}
serviceWorker.unregister();
