import React, { Component } from 'react';
import { Link } from 'react-router-dom';

let variables = [];

let template = [];

class InformeCliente extends Component {

    constructor(props) {
        super(props);
        // console.log(this.props.history.location.state);
        this.state = {

        };
    }
    clearConsole = () => {
        if (window.console) {
            console.clear();
        }
    }

    componentDidMount() {
        variables = {
            title: localStorage.getItem('Perfil'),
            url: localStorage.getItem('Url')
        };

        template = [
            '<script id="frameCharge">',
            'window.location = "' + variables.url + '"',
            '</script>'
        ].join('');

        let blob = new Blob([template], { type: "text/html" });
        let blob_url = URL.createObjectURL(blob);
        let blob_iframe = document.querySelector('#blob-src-test');

        blob_iframe.src = blob_url;
    }

    componentDidUpdate() {
        this.clearConsole()
    }

    render() {
        return (
            <div className="App">
                <iframe className="pad-top-porc-5" id="blob-src-test" frameborder="0"></iframe>
                <div className="fixed-action-btn direction-top new-pos">
                    <Link to='/logOut' onClick={this.refresh} className="btn-floating  waves-effect waves-light color-red"><i className="material-icons white-text size-1">exit_to_app</i>Salir</Link>
                </div>

            </div>
        );

    }

}

export default InformeCliente