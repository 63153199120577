import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import AltaCliente from './Components/AltaCliente';
import AltaClienteEdit from './Components/AltaClienteEdit';
import DetallesKpi from './Components/DetallesKpi';
import ListaClientes from './Components/ListaClientes';
import ListaKpis from './Components/ListaKpis';
import ListaObjetivosEnsenya from './Components/ListaObjetivosEnsenya';
import ListaGpvs from './Components/ListaGpvs';
import ListaTiendas from './Components/ListaTiendas';
import ListaProductos from './Components/ListaProductos';
import Rutas from './Components/Rutas';
import Surtidos from './Components/Surtidos';
import InformeCliente from './Components/InformeCliente'
import Login from './Components/Login';


const isAuthenticated = () => {
  return  (localStorage.getItem('login') === 'true')
};
const isAdmin = () => {
  return (localStorage.getItem('Perfil') === 'administrador')
};
const isCliente = () => {
  return (localStorage.getItem('Perfil') === 'Cliente')
};
const LogOut = () => {
  localStorage.setItem('login', false);
  return <Redirect to="/login" />
}
const MyRoute = (props) => (
isAuthenticated() && isAdmin()
? <Route {...props}/>
: isAuthenticated() && isCliente() ?  <Redirect to="/informeCliente" /> : <Redirect to="/login" />
)

const Routes = () => {
    return (
      <Switch>
        <MyRoute
          exact
          path="/"
          component={ListaClientes}
        />
        <Route  path="/logOut" component={LogOut} />
        <Route  path="/informeCliente" component={InformeCliente} />
        {/* <Route  path="/home" component={Home} /> */}
        <Route  path="/login" component={Login} />
        <Route  path="/alta-cliente" component={AltaCliente} />
        <Route  path="/alta-cliente-edit" component={AltaClienteEdit} />
        <Route  path="/detalles-kpi" component={DetallesKpi} />
        <Route  path="/lista-clientes" component={ListaClientes} />
        <Route  path="/lista-kpis" component={ListaKpis} />
        <Route  path="/lista-objetivos-ensenya" component={ListaObjetivosEnsenya} />
        <Route  path="/lista-gpvs" component={ListaGpvs} />
        <Route  path="/lista-tiendas" component={ListaTiendas} />
        <Route  path="/lista-productos" component={ListaProductos} />
        <Route  path="/rutas" component={Rutas} />
        <Route  path="/surtidos" component={Surtidos} />
      </Switch>
    );
  
}

export default Routes;