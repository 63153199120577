import React, { Component } from 'react';
import logo from '../logo.png';
import { toLower } from 'lodash';
const url_tablas = "https://www.perfect-store.es/serverside/login.php";



class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            user: '',
            pass: '',
        };
    }


    loginSubmit = () => {
        let http_request = new XMLHttpRequest();
        http_request.open("POST", url_tablas, true);
        let obj = {
            pass: this.state.pass,
            user: this.state.user
        }
        http_request.send(JSON.stringify(obj));
        http_request.onreadystatechange = () => {
            if (http_request.readyState === XMLHttpRequest.DONE && http_request.status === 200) {
                let data = JSON.parse(http_request.responseText);
                if (data.resultado === "ok") {
                    if (data.perfil === "administrador" || data.perfil === "Cliente") {
                        // console.log("Te has logueado 🎉");
                        localStorage.setItem('Perfil', data.perfil)
                        localStorage.setItem('Url', data.url)
                        localStorage.setItem('login', true)
                        window.location = "."
                    }else{
                        window.M.toast({ html: '❌ El Usuario no tiene perfil de administrador' })
                    }
                } else {
                    window.M.toast({ html: '❌ El Usuario y la contraseña no coinciden' })
                }
            }
        }
    }

    setUser = (e) => {
        let user = toLower(e.target.value);
        // console.log("User is: ", user);
        this.setState({
            user: user
        })

    }

    setPass = (e) => {
        let pass = e.target.value;
        // console.log("Pass is: ", pass);
        this.setState({
            pass: pass
        })
    }

    render() {
        return (
            <div className="App">
                <div className="header bg-orange-ps head-log ">
                    <p className="product-name">PERFECT STORE</p>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="section"></div>
                        <div className="container">
                            <div className="z-depth-3 y-depth-3 x-depth-3 grey green-text lighten-4 row div-imput">
                                <div className="section"><img id="imgLogo" alt="logo" src={logo} className="" /></div>
                                <div className="section"><i className="mdi-alert-error red-text"></i></div>
                                <div className="row">
                                    <div className="input-field col s12">
                                        <input className="validate" type="text" name="username" id="username" onBlur={this.setUser} required="" />
                                        <label htmlFor="username">Username</label>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="input-field col m12">
                                        <input className="validate" type="password" name="password" id="password" onBlur={this.setPass} required="" />
                                        <label htmlFor="password">Password</label>
                                    </div>
                                    {/* <label>
                                        <a><b>Forgot Password?</b></a>
                                    </label> */}
                                </div>
                                <br />
                                <center>
                                    <div className="row">
                                        <button onClick={this.loginSubmit} id="btn_login" className="col s5 offset-s3 btn btn-small white-text waves-effect z-depth-1 y-depth-1 bg-orange-ps btn-log">ENTRAR</button>
                                    </div>
                                </center>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}


export default Login
