import React, { Component } from 'react';
import _, { toUpper } from 'lodash';
import ButtonBack from './Elementos/ButtonBack';
const url_tablas = "https://www.perfect-store.es/serverside/objetivos_ensenya.php";

class ListaObjetivosEnsenya extends Component {

    constructor(props) {
        super(props);
        this.state = {
            idCliente: this.props.history.location.state.idCliente,
            nombreCliente: this.props.history.location.state.nombreCliente,
            objetivos: [],
            ensenyas: [],
            selectObjetivo: null,
        };
    }
    componentDidMount() {
        if (document.getElementById("titlePage")) {
            document.getElementById("titlePage").innerHTML = toUpper("Lista Objetivos " + this.state.nombreCliente);
        }
        let http_request = new XMLHttpRequest();
        http_request.open("POST", url_tablas, true);
        let obj = {
            tipo_peticion: "",
            user: {
                idCliente: parseInt(this.state.idCliente),
            }
        }
        http_request.send(JSON.stringify(obj));
        http_request.onreadystatechange = () => {
            if (http_request.readyState === XMLHttpRequest.DONE && http_request.status === 200) {
                let data = JSON.parse(http_request.responseText);
                // console.log("DATA: ", data);
                this.setState({
                    objetivos: data.objetivos,
                    ensenyas: data.ensenyas
                }, () => {
                    // console.log("state cambiado", this.state);
                    window.M.AutoInit();
                });
            }
        }

    }
    activeModalDelete = (e) => {
        let idEnsenya = e.currentTarget.getAttribute('id')
        // console.log("Enseña: ", idEnsenya);
        this.setState({
            selectObjetivo: idEnsenya
        }, () => {
            // console.log("State Borrar", this.state);
            let elem = document.getElementById("borrarObjetivo");
            let instance = window.M.Modal.getInstance(elem);
            instance.open();
        });
    }
    deleteObjetivo = () => {
        let lista = this.state.objetivos;
        let ensenya = this.state.selectObjetivo;
        let http_request = new XMLHttpRequest();
        http_request.open("POST", url_tablas, true);
        let obj = {
            tipo_peticion: "delete",
            user: {
                id_cliente: this.state.idCliente,
                idEnsenya: this.state.selectObjetivo,
            }
        }
        http_request.send(JSON.stringify(obj));
        http_request.onreadystatechange = () => {
            if (http_request.readyState === XMLHttpRequest.DONE && http_request.status === 200) {

                let dataObjetivos = _.remove(lista, function (objetivo) {
                    return objetivo.idEnsenya !== ensenya
                });

                this.setState({
                    objetivos: dataObjetivos
                }, () => {
                    window.M.toast({ html: 'El objetivo ha sido borrado' })
                    // console.log("state cambiado", this.state);
                    window.M.AutoInit();
                });
            }
        }
    }

    addObjtivo = () => {
        let lista = this.state.objetivos;
        let idEnsenya = document.getElementById("sel-ensenya").value;
        let nombreEnsenya = document.getElementById("sel-ensenya").options[document.getElementById("sel-ensenya").selectedIndex].text;
        let valorObjetivo = parseFloat(document.getElementById("input-objetivo").value);
        let existe = false;
        let dataAdd = {
            idEnsenya: idEnsenya,
            id_cliente: this.state.idCliente,
            nombre: nombreEnsenya,
            objetivo: parseFloat(valorObjetivo).toFixed(2)
        }

        lista.forEach((objetivo) => {
            // console.log("New Enseña: ", parseInt(idEnsenya));
            // console.log("Old Ensenya: ", parseInt(objetivo.idEnsenya));
            // console.log("Comparacion: ", parseInt(idEnsenya) === parseInt(objetivo.idEnsenya));
            if (parseInt(idEnsenya) === parseInt(objetivo.idEnsenya)) {
                existe = true;
            }
        });

        if (!existe) {
            let http_request = new XMLHttpRequest();
            http_request.open("POST", url_tablas, true);
            let obj = {
                tipo_peticion: "insert",
                user: dataAdd,
            }
            http_request.send(JSON.stringify(obj));
            http_request.onreadystatechange = () => {
                if (http_request.readyState === XMLHttpRequest.DONE && http_request.status === 200) {
                    // console.log("DATA: ", data);
                    lista.push(dataAdd);
                    this.setState({
                        objetivos: lista,
                    }, () => {
                        // console.log("state cambiado", this.state);
                        window.M.toast({ html: 'Se han guradado los datos' })
                        document.getElementById("input-objetivo").value = "";
                        document.getElementById("sel-ensenya").value = "-1";
                        window.M.AutoInit();
                    });
                }
            }
        } else {
            this.modalUpdateObjetivo(idEnsenya);
        }
    }
    modalUpdateObjetivo = (ensenya) => {
        this.setState({
            selectObjetivo: ensenya
        }, () => {
            // console.log("State Borrar", this.state);
            let elem = document.getElementById("updateObjetivo");
            let instance = window.M.Modal.getInstance(elem);
            instance.open();
        });
    }
    updateObjetivo = () => {
        let lista = this.state.objetivos;
        let ensenya = this.state.selectObjetivo;
        let nombreEnsenya = document.getElementById("sel-ensenya").options[document.getElementById("sel-ensenya").selectedIndex].text;
        let valorObjetivo = parseFloat(document.getElementById("input-objetivo").value);
        let http_request = new XMLHttpRequest();
        http_request.open("POST", url_tablas, true);
        let obj = {
            tipo_peticion: "update",
            user: {
                id_cliente: this.state.idCliente,
                idEnsenya: ensenya,
                objetivo: valorObjetivo
            }
        }
        // console.log("DATOS UPDATE: ", obj)
        http_request.send(JSON.stringify(obj));
        http_request.onreadystatechange = () => {
            if (http_request.readyState === XMLHttpRequest.DONE && http_request.status === 200) {
                let dataAdd = {
                    idEnsenya: ensenya,
                    id_cliente: this.state.idCliente,
                    nombre: nombreEnsenya,
                    objetivo: parseFloat(valorObjetivo).toFixed(2)
                }
                let dataObjetivos = _.remove(lista, function (objetivo) {
                    return objetivo.idEnsenya !== ensenya
                });
                dataObjetivos.push(dataAdd);
                this.setState({
                    objetivos: dataObjetivos
                }, () => {
                    window.M.toast({ html: 'El objetivo ha sido actualizado' })
                    // console.log("state cambiado", this.state);
                    window.M.AutoInit();
                });
            }
        }
    }
    chargeEnsenyas = () => {
        let html = []
        let list = this.state.ensenyas;
        list.forEach((ensenya) => {
            html.push(<option key={ensenya.idEnsenya} value={ensenya.idEnsenya}>{ensenya.nombre}</option>);
        });
        return html;
    }

    chargeTableKpis = () => {
        let html = []
        let list = this.state.objetivos

        if (list.length > 0) {
            list.forEach((objetivo) => {
                html.push(<tr key={objetivo.idEnsenya}>
                    <td className="center padding5-5"> {objetivo.nombre} </td>
                    <td className="center padding5-5"> {objetivo.objetivo} </td>
                    <td className="center padding5-5"> <div className=" btn-floating tooltipped color-red " data-position="top" data-tooltip="<a href='#' class='btn black-tooltip'>Borrar</a>" id={objetivo.idEnsenya} onClick={this.activeModalDelete}><i className="material-icons size-1 white-text">delete</i></div></td>
                </tr>);
            });
        } else {
            html.push(<tr key="0">
                <td className="center" colSpan="3" ><h5>Este cliente no tiene objetivos</h5></td>
            </tr>);
        }

        return html;
    }

    render() {
        return (
            <div className="App">
                <div className="row">
                    <div className="container margin-top-2p">


                        <div className="" >
                            <ButtonBack text="Volver" event={this.props.history.goBack}></ButtonBack>
                        </div>
                        <div className="margin-bot3p">
                            <div className=" offset-s2 input-field col s4">
                                <select id="sel-ensenya" defaultValue="-1">
                                    <option key="-1" value="-1" disabled >Seleccione una Enseña</option>
                                    {this.chargeEnsenyas()}
                                </select>
                            </div>

                            <div className="input-field col s4">
                                <input id="input-objetivo" type="number" />
                                <label htmlFor="input-objetivo">Objetivo</label>
                            </div>
                            <div className="input-field col s1">
                                <a href="#" className="btn-floating orange waves-effect waves-light" onClick={this.addObjtivo}><i className="material-icons size-1">add</i></a>
                            </div>
                        </div>
                        <table className="tabla-kpis">
                            <thead key="thead">
                                <tr>
                                    <th className="center"> Enseña </th>
                                    <th className="center"> Objetivo </th>
                                    <th className="center"> Accion </th>
                                </tr>
                            </thead>
                            <tbody key="tbody">
                                {this.chargeTableKpis()}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div id="borrarObjetivo" className="modal color-yellow">
                    <div className="modal-content">
                        <h4>Borrar Objetivo</h4>
                        <p>Estas seguro que quieres borrar el objetivo?</p>
                    </div>
                    <div className="modal-footer color-yellow">
                        <a href="#" className="modal-close waves-effect waves-green btn-flat color-green white-text" onClick={this.deleteObjetivo}>Sí, Seguro</a>
                        &nbsp; &nbsp;
                        <a href="#" className="modal-close waves-effect waves-green btn-flat color-red white-text">Cancelar</a>
                    </div>
                </div>
                <div id="updateObjetivo" className="modal color-yellow">
                    <div className="modal-content">
                        <h4>Actualizar Objetivo</h4>
                        <p>Esta enseña ya tiene objetivo. ¿Quieres actualizar el objetivo?</p>
                    </div>
                    <div className="modal-footer color-yellow">
                        <a href="#" className="modal-close waves-effect waves-green btn-flat color-green white-text" onClick={this.updateObjetivo}>Sí, Seguro</a>
                        &nbsp; &nbsp;
                        <a href="#" className="modal-close waves-effect waves-green btn-flat color-red white-text">Cancelar</a>
                    </div>
                </div>
            </div>
        );
    }

}

export default ListaObjetivosEnsenya