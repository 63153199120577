import React, { Component } from 'react';
import _, { toUpper } from 'lodash';
const url_tablas = "https://www.perfect-store.es/serverside/gpvs.php";
class ListaGpvs extends Component {

    constructor(props) {
        super(props);
        this.state = {
            gpvs: [],
            selectGpv: null
        };
    }

    componentDidMount() {
        if (document.getElementById("titlePage")) {
            document.getElementById("titlePage").innerHTML = toUpper("Lista GPV");
        }
        let http_request = new XMLHttpRequest();
        http_request.open("POST", url_tablas, true);
        let obj = {
            tipo_peticion: "",
            user: null
        }
        http_request.send(JSON.stringify(obj));
        http_request.onreadystatechange = () => {
            if (http_request.readyState === XMLHttpRequest.DONE && http_request.status === 200) {
                let data = JSON.parse(http_request.responseText);
                // console.log("DATA: ", data);
                this.setState({
                    gpvs: data
                }, () => {
                    // console.log("state cambiado", this.state);
                    const options = {dismissible:false};
                    let elem = document.getElementById("nuevoGpv");
                    window.M.Modal.init(elem, options);
                    let elem1 = document.getElementById("editarGpv");
                    window.M.Modal.init(elem1, options);
                    let elem2 = document.getElementById("borrarGpv");
                    window.M.Modal.init(elem2, options);
                });
            }
        }
    }
    switchActivo = (e) => {
        let idGpv = e.currentTarget.getAttribute('id')
        let list = [];
        let newGpv = [];
        this.state.gpvs.forEach(gpv => {
            if (gpv.idGpv === idGpv) {
                newGpv = gpv;
                if (newGpv.activo === '1') {
                    newGpv.activo = '0'
                } else {
                    newGpv.activo = '1'
                }
                list.push(newGpv)
            } else {
                list.push(gpv)
            }
        });
        let http_request = new XMLHttpRequest();
        http_request.open("POST", url_tablas, true);
        let obj = {
            tipo_peticion: "update",
            user: newGpv
        }
        http_request.send(JSON.stringify(obj));
        http_request.onreadystatechange = () => {
            if (http_request.readyState === XMLHttpRequest.DONE && http_request.status === 200) {
                this.setState({
                    gpvs: list
                }, () => {
                    window.M.AutoInit();
                });
            }
        }


    }
    activeModalAdd = (e) => {
        document.getElementById("first_name").value = '';
        document.getElementById("last_name").value = '';
        document.getElementById("team").value = '';
        document.getElementById("email").value = '';
        document.getElementById("user").value = '';
        document.getElementById("password").value = '';

        let elem = document.getElementById("nuevoGpv");
        let instance = window.M.Modal.getInstance(elem);
        instance.open();
    }
    activeModalUpdate = (e) => {
        let idGpv = e.currentTarget.getAttribute('id')
        this.state.gpvs.forEach(gpv => {

            if (gpv.idGpv === idGpv) {
                document.getElementById("edit_first_name").value = gpv.nombre;
                document.getElementById("edit_last_name").value = gpv.apellido1;
                document.getElementById("edit_email").value = gpv.email;
                document.getElementById("edit_team").value = gpv.equipo;
                document.getElementById("edit_user").value = gpv.user;
                document.getElementById("edit_password").value = gpv.pass;
            }


        });

        this.setState({
            selectGpv: idGpv
        }, () => {
            // console.log("State Borrar", this.state);
            let elem = document.getElementById("editarGpv");
            let instance = window.M.Modal.getInstance(elem);
            instance.open();
            window.M.updateTextFields();
        });

    }
    activeModalDelete = (e) => {
        let idGpv = e.currentTarget.getAttribute('id')
        // console.log("idGpv: ", idGpv);
        this.setState({
            selectGpv: idGpv
        }, () => {
            // console.log("State Borrar", this.state);
            let elem = document.getElementById("borrarGpv");
            let instance = window.M.Modal.getInstance(elem);
            instance.open();
        });
    }
    altaGpv = () => {
        let list = this.state.gpvs;
        let nombre = document.getElementById("first_name").value
        let apellido = document.getElementById("last_name").value
        let equipo = document.getElementById("team").value
        let email = document.getElementById("email").value
        let user = document.getElementById("user").value
        let pass = document.getElementById("password").value

        if (nombre !== '' && apellido !== '' && email !== '' && equipo !== '' && user !== '' && pass !== '') {
            let http_request = new XMLHttpRequest();
            http_request.open("POST", url_tablas, true);
            let newGpv = {
                idGpv: '',
                nombre: nombre,
                apellido1: apellido,
                equipo: equipo,
                email: email,
                user: user,
                pass: pass,
            }
            let obj = {
                tipo_peticion: "insert",
                user: newGpv
            }
            http_request.send(JSON.stringify(obj));
            http_request.onreadystatechange = () => {
                if (http_request.readyState === XMLHttpRequest.DONE && http_request.status === 200) {
                    let data = JSON.parse(http_request.responseText);
                    // console.log("DATA: ", data);
                    newGpv.idGpv = data.idGpv;
                    list.push(newGpv);
                    this.setState({
                        gpvs: list
                    }, () => {
                        // console.log("state cambiado", this.state);
                        let elem = document.getElementById("nuevoGpv");
                        let instance = window.M.Modal.getInstance(elem);
                        instance.close();
                        window.M.AutoInit();
                    });
                }
            }
        } else {
            window.M.toast({ html: 'Faltan campos por rellenar' })
        }
    }
    deleteGpv = () => {
        let lista = this.state.gpvs;
        let gpv = this.state.selectGpv;
        let http_request = new XMLHttpRequest();
        http_request.open("POST", url_tablas, true);
        let obj = {
            tipo_peticion: "delete",
            user: {
                idGpv: gpv
            }
        }
        http_request.send(JSON.stringify(obj));
        http_request.onreadystatechange = () => {
            if (http_request.readyState === XMLHttpRequest.DONE && http_request.status === 200) {

                let dataGpvs = _.remove(lista, function (gpv) {
                    return gpv.idGpv !== parseInt(gpv);
                });

                this.setState({
                    gpvs: dataGpvs
                }, () => {
                    window.M.toast({ html: 'El gpv ha sido borrado' })
                    // console.log("state cambiado", this.state);
                    window.M.AutoInit();
                });
            }
        }
    }
    updateGpv = () => {
        let list = this.state.gpvs;
        let idGpv = this.state.selectGpv;
        let nombre = document.getElementById("edit_first_name").value
        let apellido = document.getElementById("edit_last_name").value
        let equipo = document.getElementById("edit_team").value
        let email = document.getElementById("edit_email").value
        let user = document.getElementById("edit_user").value
        let pass = document.getElementById("edit_password").value

        if (nombre && apellido && email && equipo && user && pass) {
            let http_request = new XMLHttpRequest();
            http_request.open("POST", url_tablas, true);
            let newGpv = {
                idGpv: idGpv,
                nombre: nombre,
                apellido1: apellido,
                equipo: equipo,
                email: email,
                activo: "1",
                user: user,
                pass: pass,
            }

            let obj = {
                tipo_peticion: "update",
                user: newGpv
            }
            http_request.send(JSON.stringify(obj));
            http_request.onreadystatechange = () => {
                if (http_request.readyState === XMLHttpRequest.DONE && http_request.status === 200) {

                    // console.log("DATA: ", data);

                    let newListGpvs = _.remove(list, function (gpv) {
                        return gpv.idGpv !== idGpv;
                    })

                    newListGpvs.push(newGpv);
                    this.setState({
                        gpvs: newListGpvs
                    }, () => {
                        // console.log("state cambiado", this.state);
                        let elem = document.getElementById("editarGpv");
                        let instance = window.M.Modal.getInstance(elem);
                        instance.close();
                        window.M.toast({ html: 'El GPV ha sido actualizado' })
                        window.M.AutoInit();
                    });
                }
            }
        } else {
            window.M.toast({ html: 'Faltan campos por rellenar' })
        }
    }
    chargeListGpvs = () => {
        let html = []
        let list = this.state.gpvs

        if (list.length > 0) {
            list.forEach((gpv) => {
                let switched = [];

                if (gpv.activo === "1") {
                    switched.push(<div className="switch"><label><input type="checkbox" id={gpv.idGpv} defaultChecked onChange={this.switchActivo} /><span className="lever"></span></label></div>);
                } else {
                    switched.push(<div className="switch"><label><input type="checkbox" id={gpv.idGpv} onChange={this.switchActivo} /><span className="lever"></span></label></div>);
                }

                // console.log("ACTIVE Switch: ", gpv.activo === "1");

                html.push(<tr key={gpv.idGpv}>
                    <td className="center padding5-5 size-3"> {gpv.idGpv} </td>
                    <td className="center padding5-5 size-3"> {gpv.nombre} </td>
                    <td className="center padding5-5 size-3"> {gpv.apellido1} </td>
                    <td className="center padding5-5 size-3"> {gpv.email} </td>
                    <td className="center padding5-5 size-3"> {gpv.user} </td>
                    <td className="center padding5-5 size-3"> {gpv.pass} </td>
                    <td className="center padding5-5 size-3"> {gpv.equipo} </td>
                    <td className="center padding5-5 size-3"> {switched} </td>
                    <td className="center padding5-5 width-5">
                        <div className=" btn-floating tooltipped margin-btn color-red" data-position="top" data-tooltip="<a href='#' class='btn black-tooltip'>Borrar</a>" id={gpv.idGpv} onClick={this.activeModalDelete}><i className="material-icons size-1 white-text">delete</i>
                        </div><div className=" btn-floating margin-btn tooltipped color-blue " data-position="top" data-tooltip="<a href='#' class='btn black-tooltip'>Editar Gpv</a>" id={gpv.idGpv} onClick={this.activeModalUpdate}><i className="material-icons size-1 white-text">edit</i>
                        </div></td>
                </tr>);
            });
        } else {
            html.push(<tr key="0">
                <td className="center" colSpan="2" >Este cliente no tiene objetivos</td>
            </tr>);
        }

        return html;
    }

    render() {
        return (
            <div className="App">
                <div className="row">
                    <div className="container margin-top-2p">

                        <div className="waves-effect waves-light btn right margin-bot3p" onClick={this.activeModalAdd}><i className="material-icons right">add</i>Nuevo Gpv</div>
                        <table className="tabla-kpis">
                            <thead key="thead">
                                <tr>
                                    <th className="center"> Id </th>
                                    <th className="center"> Nombre </th>
                                    <th className="center"> Apellido </th>
                                    <th className="center"> Email </th>
                                    <th className="center"> User </th>
                                    <th className="center"> Password </th>
                                    <th className="center"> Equipo </th>
                                    <th className="center"> Activo </th>
                                    <th className="center"> Accion </th>
                                </tr>
                            </thead>
                            <tbody key="tbody">
                                {this.chargeListGpvs()}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div id="borrarGpv" className="modal color-yellow border-rad">
                    <div className="modal-content">
                        <h4>Borrar Gpv</h4>
                        <p>Estas seguro que quieres borrar este Gpv?</p>
                    </div>
                    <div className="modal-footer color-yellow">
                        <a href="#" className="modal-close waves-effect waves-green btn-flat color-green white-text" onClick={this.deleteGpv}>Sí, Seguro</a>
                        &nbsp; &nbsp;
                        <a href="#" className="modal-close waves-effect waves-green btn-flat color-red white-text">Cancelar</a>
                    </div>
                </div>
                <div id="nuevoGpv" className="modal color-yellow border-rad">
                    <div className="modal-content">
                        <h4>Añadir Gpv</h4>
                        <form className="col s12">
                            <div className="row">
                                <div className="input-field col s6">
                                    <input id="first_name" type="text" className="validate" />
                                    <label htmlFor="first_name">Nombre</label>
                                </div>
                                <div className="input-field col s6">
                                    <input id="last_name" type="text" className="validate" />
                                    <label htmlFor="last_name">Apellido</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-field col s6">
                                    <input id="email" type="email" className="validate" />
                                    <label htmlFor="email">Email</label>
                                </div>
                                <div className="input-field col s6">
                                    <input id="team" type="text" className="validate" />
                                    <label htmlFor="team">Equipo</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-field col s6">
                                    <input id="user" type="text" className="validate" />
                                    <label htmlFor="user">Usuario</label>
                                </div>
                                <div className="input-field col s6">
                                    <input id="password" type="password" />
                                    <label htmlFor="password">Password</label>
                                </div>
                            </div>
                        </form>
                        <div className=" col s12">
                            <a href="#" className="waves-effect waves-green btn-modal btn-flat color-green white-text right" onClick={this.altaGpv}>Dar de Alta</a>
                            <a href="#" className="modal-close waves-effect waves-green btn-modal btn-flat color-red white-text right">Cancelar</a>
                        </div>

                    </div>
                </div>
                <div id="editarGpv" className="modal color-yellow border-rad">
                    <div className="modal-content">
                        <h4>Actualizar Gpv</h4>
                        <form className="col s12">
                            <div className="row">
                                <div className="input-field col s6">
                                    <input id="edit_first_name" type="text" className="validate" />
                                    <label htmlFor="edit_first_name">Nombre</label>
                                </div>
                                <div className="input-field col s6">
                                    <input id="edit_last_name" type="text" className="validate" />
                                    <label htmlFor="edit_last_name">Apellido</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-field col s6">
                                    <input id="edit_email" type="email" className="validate" />
                                    <label htmlFor="edit_email">Email</label>
                                </div>
                                <div className="input-field col s6">
                                    <input id="edit_team" type="text" className="validate" />
                                    <label htmlFor="edit_team">Equipo</label>
                                </div>
                            </div>
                            <div className="row">
                                <div className="input-field col s6">
                                    <input id="edit_user" type="text" className="validate" />
                                    <label htmlFor="edit_user">Usuario</label>
                                </div>
                                <div className="input-field col s6">
                                    <input id="edit_password" type="password" />
                                    <label htmlFor="vpassword">Contraseña</label>
                                </div>
                            </div>
                        </form>
                        <div className=" col s12">
                            <a href="#" className="waves-effect waves-green btn-modal btn-flat color-green white-text right" onClick={this.updateGpv}>Actualizar</a>
                            <a href="#" className="modal-close waves-effect waves-green btn-modal btn-flat color-red white-text right">Cancelar</a>
                        </div>

                    </div>
                </div>
            </div>
        );
    }

}

export default ListaGpvs